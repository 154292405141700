import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ProductCard from "../store/ProductCard";
import { ClipLoader } from "react-spinners"; // Import the spinner

const SearchResultsPage = () => {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const query = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    const fetchSearchResults = async () => {
      setLoading(true); // Set loading to true when starting the fetch
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}/api/product/search?t=${query}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status !== 200) {
          throw new Error("Failed to fetch search results");
        }
        const data = response.data;
        setSearchResults(data.results);
        setSuggestions(data.suggestions);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setLoading(false); // Set loading to false once the fetch is complete
      }
    };

    if (query) {
      fetchSearchResults();
    }
  }, [query]);

  return (
    <div className="flex flex-col min-h-screen font-urbanist">
      <div className="p-4">
        <h1 className="text-2xl font-semibold mb-4">Search Results for "{query}"</h1>
      </div>
      <div className="flex flex-grow flex-wrap p-4">
        {loading ? (
          <div className="flex justify-center items-center w-full h-full">
            <ClipLoader size={50} color={"#123abc"} loading={loading} /> {/* Spinner */}
          </div>
        ) : searchResults.length > 0 ? (
          <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {searchResults.map((result, index) => (
              <ProductCard
                key={index}
                _id={result._id}
                product_name={result.product_name}
                Combination={result.Combination}
              />
            ))}
          </div>
        ) : (
          <p>No results found</p>
        )}
      </div>
    </div>
  );
};

export default SearchResultsPage;
