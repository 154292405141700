import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Signin = ({ onClose, onLogin }) => {
  const [otpSent, setOtpSent] = useState(false);
  const [contact, setContact] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');

  const handleSendOtp = async () => {
    if (contact) {
      const isEmail = contact.includes('@');
      if (!isEmail) {
        if (contact.length !== 10 || isNaN(contact)) {
          setError('Please provide a valid 10-digit mobile number or email');
          return;
        }
      } 
      setLoading(true);
      setError(''); 
  
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const data = isEmail ? { email: contact } : { contactNumber: contact };
  
        const response = await axios.post(`${apiUrl}/api/users/login`, data);
        console.log('Response from server:', response.data); // Log server response
        setOtpSent(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error sending OTP:', error.response ? error.response.data : error.message); // Log error details
        if (error.response && error.response.data === 'User not found') {
          setError('User not found. Please sign up first.');
          return;
        }
        setError('Failed to send OTP');
      }
    } else {
      setError('Please provide a valid email or mobile number');
    }
  };
  

  const handleVerifyOtp = async () => {
    if (otp) {
      setLoading(true);
      setError('');
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const isEmail = contact.includes('@');
        const data = isEmail ? { email: contact, otp } : { contactNumber: contact, otp };
        const response = await axios.post(`${apiUrl}/api/users/logotp`, data);
        console.log('OTP Verified:', response.data); // Log response
        setLoading(false);
        toast.success('OTP Verified Successfully', {
          onClose: onClose // Call onClose after toast is displayed
        });

        // Fetch user data after successful login
        fetchUserData(contact);
      } catch (error) {
        console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
        setLoading(false);
        toast.error('Invalid OTP');
      }
    }
  };

  const fetchUserData = async (contact) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const isEmail = contact.includes('@');
      const url = isEmail ? `${apiUrl}/api/users/getUsers/${contact}` : `${apiUrl}/api/users/getUsers/${contact}`;
      const response = await axios.get(url);

      if (!response.data) {
        throw new Error('User not found');
      }

      // Call onLogin with user data
      onLogin(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Failed to fetch user data');
    }
  };

  const handleResendOtp = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const isEmail = contact.includes('@');
      const data = isEmail ? { email: contact } : { contactNumber: contact };

      await axios.post(`${apiUrl}/api/users/login`, data);
      toast.info('OTP Resent');
    } catch (error) {
      setError('Failed to resend OTP');
    }
  };

  const handleChangeContact = () => {
    setOtpSent(false);
    setContact('');
    setOtp('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      otpSent ? handleVerifyOtp() : handleSendOtp();
    }
  };

  return (
    <div className="py-2">
      <div className="text-left">
        <div className="flex justify-between items-center">
          <p className="mt-2 text-[18px] font-normal text-black sm:text-2xl">
            Sign in to iGalaxy
          </p>
          <button
            type="button"
            className="text-black focus:outline-none"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <p className="mt-1 text-base leading-7 text-gray-600 text-[25px]">
          Sign in with your mobile number or email to continue
        </p>

        {error && <p className="text-red-500">{error}</p>}

        <div className="mt-6 flex justify-between items-center">
          <p className="text-[17px] font-normal text-black">
            Email or Mobile Number*
          </p>
          {otpSent && (
            <button
              type="button"
              className="text-blue-700 font-normal text-base focus:outline-none"
              onClick={handleChangeContact}
            >
              Change
            </button>
          )}
        </div>
        <input
          type="text"
          placeholder="Enter Mobile Number or Email"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          onKeyDown={handleKeyDown} 
          className="mt-2 w-full p-2 border border-gray-300 rounded-md"
          disabled={otpSent}
        />

        {otpSent && (
          <>
            <div className="mt-6 flex justify-between items-center">
              <p className="font-md text-black">
                Enter OTP*
              </p>
              <button
                type="button"
                className="text-blue-700 font-normal text-base focus:outline-none"
                onClick={handleResendOtp}
              >
                Resend
              </button>
            </div>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              onKeyDown={handleKeyDown} 
              className="mt-2 w-full p-2 border border-gray-300 rounded-md"
            />
          </>
        )}

        <div className="mt-[30px] flex gap-4">
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 rounded-full text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue bg-blue-700"
            onClick={otpSent ? handleVerifyOtp : handleSendOtp}
            disabled={loading}
          >
            {loading ? 'Processing...' : otpSent ? 'Verify OTP' : 'Send OTP'}
          </button>
          <div className='flex justify-between gap-5'>
            <p className="text-gray-600 mt-2">
              New user?
            </p>
            <button
              type="button"
              className="text-blue-700 font-normal text-base focus:outline-none"
              onClick={() => onClose(false)}
            >
              Signup
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SigninPopup = ({ onClose, onSignin, onLogin }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#6B7280] bg-opacity-50">
      <div className="bg-white p-[50px] rounded-[20px] shadow-md border w-[500px] h-[500px] relative">
        <Signin onClose={onClose} onSignin={onSignin} onLogin={onLogin} />
      </div>
    </div>
  );
};

export default SigninPopup;
