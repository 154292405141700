import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Signin = ({ onClose, address }) => {
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [pincode, setPincode] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    if (address) {
      setName(address.name || '');
      setMobileNumber(address.mobileNumber || '');
      setPincode(address.pincode || '');
      setFullAddress(address.fullAddress || '');
      setCity(address.city || '');
      setState(address.state || '');
      setIsDefault(address.isDefault || false);
    }
  }, [address]);

  const handleAddAddress = async () => {
    if (!name || !mobileNumber || !pincode || !fullAddress || !city || !state) {
      toast.error('Please fill out all fields.');
      return;
    }
    if (mobileNumber.length !== 10) {
      toast.error('Please enter a valid 10-digit mobile number.');
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        console.log("User not logged in");
        toast.error('User not logged in');
        return;
      }

      console.log("User details:", user);

      if (!user.email && !user.contactNumber) {
        console.log("User details incomplete");
        toast.error('User details incomplete');
        return;
      }

      const payload = {
        name,
        mobileNumber,
        pincode,
        fullAddress,
        city,
        state,
        isDefault,
        email: user.email,
        contactNumber: user.contactNumber,
      };

      let response;
      if (address) {
        payload.addressId = address._id;
        response = await axios.put(`${apiUrl}/api/users/updateAddress`, payload);
      } else {
        response = await axios.post(`${apiUrl}/api/users/addAddress`, payload);
      }

      if (response.status === 201 || response.status === 200) {
        toast.success(address ? 'Address updated successfully!' : 'Address added successfully!');
        onClose();
      } else {
        toast.error('Failed to save address.');
      }
    } catch (error) {
      console.error("Error saving address:", error);
      toast.error('Failed to save address.');
    }
  };

  return (
    <div className="py-2">
      <div className="text-left">
        <div className="flex justify-between items-center">
          <p className="mt-2 text-[24px] font-normal text-black sm:text-2xl">
            {address ? 'Update Address' : 'Add Address'}
          </p>
          <button
            type="button"
            className="text-black focus:outline-none"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="mt-6">
          <div className="mb-4">
            <label className="block text-sm font-normal text-black">Name*</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              className="mt-1 w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-normal text-black">Mobile Number*</label>
            <input
              type="text"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder="Enter mobile number"
              maxLength="10"
              className="mt-1 w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-normal text-black">Pincode*</label>
              <input
                type="text"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                placeholder="Enter pincode"
                className="mt-1 w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-normal text-black">Full Address*</label>
              <input
                type="text"
                value={fullAddress}
                onChange={(e) => setFullAddress(e.target.value)}
                placeholder="Enter full address"
                className="mt-1 w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-normal text-black">City*</label>
              <input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter city"
                className="mt-1 w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-normal text-black">State*</label>
              <input
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="Enter state"
                className="mt-1 w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={isDefault}
                onChange={() => setIsDefault(!isDefault)}
                className="form-checkbox"
              />
              <span className="ml-2 text-base text-black">Set as default</span>
            </label>
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              className="inline-flex w-full items-center justify-center rounded-full border px-7 py-2 text-[17px] font-normal text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue bg-blue-700"
              onClick={handleAddAddress}
            >
              {address ? 'Update Address' : 'Add Address'}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const AddAddress = ({ onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#6B7280] bg-opacity-50">
      <div className="bg-white p-[50px] rounded-[20px] shadow-md border w-[500px] h-auto relative">
        <Signin onClose={onClose} />
      </div>
    </div>
  );
};

export default AddAddress;
