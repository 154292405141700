import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Filter from './Filter';
import ProductCard from './ProductCard';
import { RingLoader } from 'react-spinners'; // Importing the spinner component

const StoreItem = () => {
  const { type } = useParams();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const categories = [
    { name: "Mobile", image: "/assets/smartphone.svg" },
    { name: "Watch", image: "/assets/watches.svg" },
    { name: "Pre-Owned", image: "/assets/smartphone.svg" },
    { name: "Tablet", image: "/assets/tablets.svg" },
    { name: "Laptop", image: "/assets/laptop.svg" },
    { name: "Earphone", image: "/assets/headphones.svg" },
    { name: "TV", image: "/assets/Tv.svg" },
    { name: "Soundbar", image: "/assets/soundbar.svg" },
    { name: "Gaming", image: "/assets/gamming.svg" },
    { name: "Camera", image: "/assets/camera.svg" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/api/product/type/${type}`);
        setProducts(response.data.reverse());
        setFilteredProducts(response.data); // Initialize filtered products
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Ensure loading is false even if there's an error
      }
    };

    fetchData();
     window.scrollTo(0, 0);
  }, [type]);

  const handleFilterChange = (filters) => {
    const filtered = products.filter(product => {
      const primaryCombination = product.Combination['1comb'][0];
      const { discounted_price, price } = primaryCombination;

      // Apply filter conditions
      // if (filters.newest) {
      //   // Apply newest filter logic (assuming products have a date field)
      // }
      if (filters.topRated) {
        // Apply top-rated filter logic (assuming products have a rating field)
      }
      if (filters.recommended) {
        // Apply recommended filter logic (assuming products have a recommended field)
      }
      if (filters.flagshipSeries && !product.flagshipSeries) {
        return false;
      }
      if (filters.offerAvailable && !primaryCombination.discounted_price) {
        return false;
      }
      if (filters.priceRange.length > 0) {
        const priceInRange = filters.priceRange.some(range => {
          const [min, max] = range.split('-').map(Number);
          return (discounted_price || price) >= min && (discounted_price || price) <= max;
        });
        if (!priceInRange) {
          return false;
        }
      }

      return true;
    });

    if (filters.priceHighToLow) {
      filtered.sort((a, b) => b.Combination['1comb'][0].price - a.Combination['1comb'][0].price);
    }
    if (filters.priceLowToHigh) {
      filtered.sort((a, b) => a.Combination['1comb'][0].price - b.Combination['1comb'][0].price);
    }

    setFilteredProducts(filtered);
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-100 z-50">
        <RingLoader color="#82558a" size={60} />
      </div>
    );
  }

  return (
    <div className="flex divide-y-2 flex-col font-urbanist">
      <div>
        <div className="md:px-32 mt-5 mb-4 p-2 bg-gray-50">
          <div
            className="flex px-8 overflow-x-auto whitespace-nowrap justify-between"
            style={{
              overflowX: 'scroll',
              msOverflowStyle: 'none', // IE and Edge
              scrollbarWidth: 'none' // Firefox
            }}
          >
            {categories.map((category, index) => (
              <div className='text-center' key={index}>
                <Link
                  to={`/Shop/${category.name}`}
                  className="inline-block bg-white rounded-full h-24 w-24 m-2 text-center content-center justify-center sm:m-1"
                >
                  <img src={category.image} alt={category.name} className="w-16 mt-1 h-16 object-contain mb-2 mx-auto" />
                </Link>
                <p className="hover:border-b hover:border-black cursor-pointer text-sm">{category.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row divide-x-2">
        <Filter onFilterChange={handleFilterChange} />
        <div className="grid px-4 grid-cols-2 lg:grid-cols-3 mt-6 gap-6 md:gap-10">
          {filteredProducts.map((product, index) => (
            <ProductCard
              key={index}
              _id={product._id}
              product_name={product.product_name}
              Combination={product.Combination}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default StoreItem;

// Additional CSS for hiding scrollbar in Chrome, Safari, and newer browsers
const style = document.createElement('style');
style.innerHTML = `
  ::-webkit-scrollbar {
    display: none;
  }
`;
document.head.appendChild(style);
