import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RingLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
          console.error('User not logged in');
          setLoading(false);
          return;
        }
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/api/users/getwish`, {
          params: { email: user.email, contactNumber: user.contactNumber }
        });

        const fetchedItems = response.data.wishlistItems;
        setWishlistItems(Array.isArray(fetchedItems) ? fetchedItems : []);
      } catch (error) {
        console.error('Error fetching wishlist items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWishlist();
  }, []);

  const removeFromWishlist = async (productId, selectedColor, selectedStorage) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await axios.post(`${apiUrl}/api/users/wishre`, {
        email: user.email,
        contactNumber: user.contactNumber,
        productId,
        selectedColor,
        selectedStorage
      });

      setWishlistItems(response.data);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-100 z-50">
        <RingLoader color="#82558a" size={60} />
      </div>
    );
  }

  return (
    <div className=''>
      <h2 className="text-center font-bold text-3xl mt-6 border-b p-5">
        Wishlist
      </h2>
      <div className="p-4 md:p-10 bg-slate-50">
        {wishlistItems.length === 0 ? (
          <p className="text-center">Your wishlist is empty.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {wishlistItems.map((item) => {
              const imageUrl = item.images && item.images[item.colorCode] ? item.images[item.colorCode] : item.image;
              return (
                <div
                  key={item.product}
                  className="p-4 bg-white rounded-md shadow-sm cursor-pointer"
                  onClick={() => navigate(`/product/${item.product}`)} // Navigate to product page
                >
                  <img
                    src={imageUrl}
                    alt={item.name}
                    className="w-full h-48 object-contain mb-4"
                  />
                  <div className='flex justify-between'>
                    <div>
                      <h3 className="text-lg line-clamp-1 font-semibold">{item.name}</h3>
                      <div className='flex gap-2'>
                        <p className="text-xl font-medium">₹{item.discounted_price}</p>
                        {item.price && (
                          <p className="text-lg font-medium text-gray-500 line-through">
                            ₹{item.price}
                          </p>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); 
                        removeFromWishlist(item.product, item.colorCode, item.storageCode);
                      }}
                      className="mt-4 bg-gray-200 rounded-full text-black px-4 py-2 hover:bg-gray-400 transition duration-300"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Wishlist;
