import React from 'react'

export default function Policy() {
  return (
    <>
    <section className="mx-auto text-left max-w-6xl px-5 py-10 md:px-0">
    <h1 className='text-3xl font-normal '>Privacy Policy</h1>
    <div className=' text-base mt-6'>
    <p>
        IGALAXY Mobiles India Private Limited, having its registered office at 5th Floor, Tower B, Building No 8, DLF Cyber City, Gurgaon, Haryana 122002, (hereinafter interchangeably referred to as IGALAXY, we, or us), is committed to protecting and respecting your privacy. We may collect, use, disclose, transfer, process, share, and store your data, including Personal Information and Sensitive Personal Data or Information (collectively referred to as Your Information) when you access, browse, avail, order, subscribe, or use any of our products or services on or through the app (hereinafter referred to as the Platform). By doing so, you unequivocally agree to be bound by this Privacy Policy. Please read carefully and familiarize yourself with our privacy practices before using our Platform, applying for any of our products or services, and/or providing Your Information on or through the Platform or engaging in any of the permitted activities on the Platform.
      </p>
      <br/>
      <p>
        By using the Platform, you may access, browse, and/or apply for services and/or products (Services) available on the Platform, which may be updated from time to time. In this Privacy Policy, we explain the purposes, methods, and scope of our collection and use of Your Information, your rights in and/or with respect to Your Information, and the security measures we take to protect Your Information.
      </p>
      <br/>
      <p>
        A. Definitions
      </p>
      <br/>
      <p>
        "Personal Information" refers to any information that relates to a natural person, which either directly or indirectly, in combination with other information available or likely to be available with a body corporate, is capable of identifying such natural person.
      </p>
      <br/>
      <p>
        "Sensitive Personal Data or Information" shall mean as defined in the Information Technology Act, 2000, and the notification dated 11th April 2011 issued by the Ministry of Communications and Information Technology (Department of Information Technology).
      </p>
      <br/>
      <p>
        B. How We Collect and Use Your Information
      </p>
      <br/>
      <p>
        We may collect Your Information for efficient operations and to provide you with a good user experience. Our channels for collecting Your Information shall be (a) information directly provided by you to us.
      </p>
      <br/>
      <p>
        The information we collect depends on the service and product you're using, the environment in which you interact with us, the choices you make, and the products and services you view and use. If you choose not to permit us to collect such information, we may not be able to provide you with relevant products and/or respond to your queries or resolve any problems you encounter.
      </p>
      <br/>
      <p>
        a. Information directly provided by you to us
      </p>
      <br/>
      <p>
        i. Some services we offer may require that you submit Your Information to us directly.
      </p>
      <br/>
      <p>
       ii. If you wish to place an order on our Platform, you will need to create an account and log into your account. Before registering for an account, you will need to agree to the Privacy Notice of HeyTop ID <a href="https://muc.heytop.com/document/heytap/oversea/privacyPolicy/privacyPolicy_en_US.html">https://muc.heytop.com/document/heytap/oversea/privacyPolicy/privacyPolicy_en_US.html</a>. HeyTop ID will collect your mobile phone number, email address, password, profile picture, user name, etc.
      </p>
      <br/>
      <p>
        In order to apply for the purchase of any product, you may need to provide us with Your Information, including but not limited to your name, residential/shipping address, order details, mobile phone number, email address, or other contact details.
      </p>
      <br/>
      <p>
        In order to provide you with the warranty service of the product, you may need to provide us with the IMEI of the IGALAXY product purchased by you.
      </p>
      <br/>
      <p>
        iii. In case we provide you with a chat function or any other function on the Platform to contact our customer service, we may collect Your Information, including but not limited to your account ID, identity verification details, contact details, order details, chat history, complaint or query-related details, details about the product or services being inquired about, etc.
      </p>
      <p>
        iv. In order to fulfil the obligation of network security protection, ensure the normal operation of IGALAXY store and the security of your account, and at the same time to improve and optimize your service experience, we will collect your device information and your information about how your device interacts with IGALAXY Store, including device name, device model, application, serial number, Google ID, operating system version, and other device information and your login account operational behaviors (including changing the password, changing the bound mobile phone number, mailbox number, appeal record, the time and duration of using the IGALAXY Store, search query words entered through the service, and software event information such as crashes, restarts, lag, records such as upgrades, errors).<br/><br/>
      </p>
      <p>
        v. From time to time, we organize various activities on the Platform. To participate in the activities, you may have to provide us with your user ID and mobile phone number. Depending on the nature of the activities, you may need to provide us with other personal information. We only collect the minimum information to facilitate your participation in the activities. We will specify the information you need to provide in the terms of each activity.<br/><br/>
      </p>
      <p>
       vi.  After you place an order with us, you could also trade your old smartphone (brand, model, memory, and IMEI of the old smartphone) to obtain the quotation for a discount for your new smartphone. You need to fill in the details of its value. We share the above-mentioned information with Cashify (<a href="http://www.cashify.in/privacy-policy">http://www.cashify.in/privacy-policy</a>) to obtain the quotation. Then the Platform generates an order for your new smartphone and a recycling order at the same time. The two orders are bound to each other. Your name, address, and phone number will be shared with Cashify to collect your old smartphone to fulfill your recycling order.<br/><br/>
      </p>
      <p>
        vii. If you wish to use the code shared by your friends to participate in the IGALAXY Store marketing activities or view products shared by your friends, we will read the contents of your mobile phone clipboard to quickly and easily identify the link of the activity or product you wish to participate in.<br/><br/>
      </p>
      <p>
        viii. If you wish to upgrade your membership level and enjoy the benefits by linking the device to you, we will collect your device ID and you may need to fill in your IMEI manually if the system fails to identify it.<br/><br/>
      </p>
      <p>
        ix. If you search products in the IGALAXY Store, we may collect your Android ID for accurate search results.<br/><br/>
      </p>
      <p>
        The use of cookies and other similar technology:
      </p>
      <br/>
      <p>
      IGALAXY and its partners use cookies or similar tracking technologies to better understand the use of mobile software on your device.
      </p>

    </div>
    </section>
    </>
  )
}
