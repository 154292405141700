import React, { useEffect, useRef } from 'react';

const Callus = ({ isOpen, onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center font-urbanist z-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div ref={modalRef} className="bg-white rounded-lg p-8 w-full max-w-3xl">
        <div className='flex justify-between'>
            <div className='mb-10'>
            <h2 className="text-2xl mt-2 font-semibold text-gray-900">Contact iGalaxy</h2>
            <p className='text-gray-500'>Get Support from the iGalaxy 24/7</p>
        </div>
        <button onClick={onClose} className="text-red-700 -mt-10 text-xl">×</button>
        </div>
        
        <div className="flex flex-col md:flex-row lg:flex-row justify-between gap-8">
          <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.055937729777!2d88.36801897475614!3d22.53957723418081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02770a0745b971%3A0x17a733e8b85560bf!2siGalaxy!5e0!3m2!1sen!2sin!4v1722379469983!5m2!1sen!2sin" width="400" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div>
            <p className='text-lg'><span className='font-semibold'>Address</span> : 3 Dr biresh guha street Kolkata 700017</p>
            <p className='text-lg'><span className='font-semibold'>Email</span> :  Igalaxy.co@gmail.com</p>
            <p className='text-lg'><span className='font-semibold'>Mob No</span> : +91 8961722385</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Callus;
