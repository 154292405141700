import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { toast, ToastContainer } from 'react-toastify';
import ContactForm from './ContactForm';
const apiUrl = process.env.REACT_APP_API_URL;


const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');  
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/subscribe`, { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error subscribing. Please try again later.');
    }
  };
  return (
    <footer className="bg-[#111111] max-w-full text-gray-300 p-8 mt-5">
      <ToastContainer />
      <ContactForm isOpen={isContactFormOpen} onClose={() => setIsContactFormOpen(false)} />
      <div className="mt-10 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-8 gap-8">
        <div>
          <Link to="/Shop/Mobile" className="font-bold text-white mb-4">Phones</Link>
          <ul className='font-light'>
            <li>All Phones</li>
            <li>Android Phones</li>
            <li>iPhones</li>
            <li>Flip & Fold Phones</li>
            <li>Feature Phones</li>
            <li>Refurbished Phones</li>
            <li>Gaming Phones</li>
            <li>Accessories</li>
          </ul>
        </div>
        <div>
          <Link to="/Shop/Watch" className="font-bold text-white mb-4">Watches</Link>
          <ul className='font-light'>
            <li>All Watches</li>
            <li>Smart Bands</li>
            <li>Smart Watches</li>
            <li>iOS & Android</li>
            <li>Amoled Watches</li>
            <li>Accessories</li>
          </ul>
        </div>
        <div>
          <Link to="/Shop/Tablet" className="font-bold text-white mb-4">Tablets</Link>
          <ul className='font-light'>
            <li>All Tablets</li>
            <li>Android Tablets</li>
            <li>Windows Tablets</li>
            <li>iPads</li>
            <li>Kindles</li>
            <li>Digital Pads</li>
            <li>Accessories</li>
          </ul>
        </div>
        <div>
          <Link to="/Shop/Laptop" className="font-bold text-white mb-4">Laptops</Link>
          <ul className='font-light'>
            <li>All Laptops</li>
            <li>Windows Laptops</li>
            <li>Macbooks</li>
            <li>Gaming Laptops</li>
            <li>2-in-1 Laptops</li>
            <li>Thin & Light Laptops</li>
            <li>Editing Laptops</li>
            <li>Accessories</li>
          </ul>
        </div>
        <div>
          <Link to="/Shop/Earphone" className="font-bold text-white mb-4">Audio</Link>
          <ul className='font-light'>
            <li>All Audio</li>
            <li>Bluetooth Earphones</li>
            <li>Bluetooth Headphones</li>
            <li>Earphones</li>
            <li>Headphones</li>
            <li>Truly Wireless Earbuds</li>
            <li>Speakers</li>
            <li>Bluetooth Speakers</li>
            <li>Home Theaters</li>
          </ul>
        </div>
        <div>
          <Link to="/Shop/Tv" className="font-bold text-white mb-4">Video</Link>
          <ul className='font-light'>
            <li>All Video</li>
            <li>Full HD Monitors</li>
            <li>Gaming Monitors</li>
            <li>4K Monitors</li>
            <li>Android Smart TV</li>
            <li>Full HD TV</li>
            <li>4k TVs</li>
            <li>OLED TVs</li>
            <li>QLED TVs</li>
          </ul>
        </div>
        <div>
          <Link to="/Shop/Camera" className="font-bold text-white mb-4">Cameras</Link>
          <ul className='font-light'>
            <li>All Cameras</li>
            <li>DSLRs</li>
            <li>Mirrorless Cameras</li>
            <li>Full-Frame Cameras</li>
            <li>Instant Cameras</li>
            <li>Camera Lens</li>
            <li>Drone Cameras</li>
            <li>CCTV Cameras</li>
            <li>Accessories</li>
          </ul>
        </div>
        <div>
          <Link to="/Shop/Gaming" className="font-bold text-white mb-4">Gaming</Link>
          <ul className='font-light'>
            <li>All Gaming</li>
            <li>Gaming Consoles</li>
            <li>Gaming Laptops</li>
            <li>Gaming PCs</li>
            <li>PlayStation</li>
            <li>XBOX</li>
            <li>Accessories</li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between border-t border-gray-700 mt-8 pt-6">
        <div className="flex items-center space-x-10 mb-4 md:mb-0">
          <img src="assets/whitelogo.png" alt="iGALAXY Logo" className="h-8" />
          <div className='flex flex-row ml-3 gap-2 md:hidden'>
            <Link to="https://www.instagram.com/igalaxy.co.in?igsh=MXF6em5sMzY5aXQ1cQ==" className="text-white text-4xl">
            <img src="assets/insta.svg" alt="" className='h-8 mt-1'/>
          </Link>
          <Link to="https://www.instagram.com/igalaxy.co.in?igsh=MXF6em5sMzY5aXQ1cQ==" className="text-white mt-[3px] text-3xl">
          <img src="assets/face.svg" alt="" className='h-8'/>
          </Link>
            </div>
          <p className='hidden md:visible'>© 2024 iGalaxy. All rights reserved.</p>
  
        </div>
        <div className="flex">
            <div>
            <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter email for updates"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="p-2 pl-2 rounded-full w-80 bg-white text-gray-800"
            />
            <button type="submit" className="-ml-10 -mt-1 align-middle p-2 bg-blue-600 rounded-full text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </button>
          </form>
          {message && <p>{message}</p>}
        </div>
            <div className='hidden md:flex flex-row ml-3 gap-2'>
            <Link to="https://www.instagram.com/igalaxy.co.in?igsh=MXF6em5sMzY5aXQ1cQ==" className="text-white text-4xl">
            <img src="assets/insta.svg" alt="" className='h-8 mt-1'/>
          </Link>
          <Link to="https://www.instagram.com/igalaxy.co.in?igsh=MXF6em5sMzY5aXQ1cQ==" className="text-white mt-1 text-3xl">
          <img src="assets/face.svg" alt="" className='h-8 '/>
          </Link>
            </div>
        </div>
      </div>
      
      <div className="grid md:grid-cols-4 text-gray-500 text-sm mt-6 space-y-2 md:space-y-0">
        <div className='md:flex md:flex-row gap-4 grid grid-cols-2 col-span-3 mb-2'>
        <button onClick={() => setIsContactFormOpen(true)} className='-ml-[81px] md:ml-0'>Contact us</button>
        <Link className='' to="/Faq" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>FAQs</Link>
          <Link className='' to="/Terms&Conditions" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Terms & Conditions</Link>
          <Link className='' to="Policy" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Privacy Policy</Link>
          <Link className='' to="Shipping" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Shipping Policy</Link>
          <Link className='' to="Cancellation" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Cancellation Policy</Link>
          <Link className='hidden lg:block' to="Returns" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Returns & Refunds</Link>
          <p className='lg:block hidden' to="">© 2024 iGalaxy. All rights reserved.</p>
          <a className='lg:block hidden' href="https://nbtservices.framer.website/" target="_blank" rel="noopener noreferrer">Made by nbt services</a>

        </div>
        
          <div className='md:text-right'>
          <p className='md:hidden mb-4'>© 2024 iGalaxy. All rights reserved.</p>
          <a className='block md:hidden lg:hidden mb-4' href="https://nbtservices.framer.website/" target="_blank" rel="noopener noreferrer">Made by nbt services</a>
          <Link className="text-gray-100" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Back to Top ↑</Link>
          </div>

          <div className='md:flex hidden lg:hidden'>
          <Link className='' to="Returns">Returns & Refunds</Link>
          <p className='hidden' to="">© 2024 iGalaxy. All rights reserved.</p>
          </div>
          
      </div>
      
    </footer>
  );
};

export default Footer;
