import React, { Component } from 'react'

export class OffersDeals extends Component {
  render() {
    return (
      <div>
        <div className=' p-6 md:px-20'>
        <h1 className="text-2xl font-bold mb-6">Offers & Deals</h1>
          <div className='flex overflow-x-auto gap-8 h-[445px] content-center'>
              <img className='object-cover' src='assets/offer1.png' alt=''/>
              <img className='object-cover' src='assets/offer1.png' alt=''/>
              <img className='object-cover' src='assets/offer1.png' alt=''/>
          </div>
        </div>
      </div>
    )
  }
}

export default OffersDeals
