import React, { useState, useEffect, useRef } from "react";
import { MdSearch } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiUserLine } from "react-icons/ri";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { IoIosClose } from "react-icons/io";
import Modal from "react-modal";
import Profile from "../auth/Profile";
import ShoppingBag from "../cart/CartButton";
import Profile1 from "../auth/Profile1";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

Modal.setAppElement("#root");

const TabSelector = ({ selectedTab, handleTabClick, id }) => {
  const tabs = [
    { name: "Buy", path: "/" },
    { name: "Sell", path: "/sell" },
    { name: "Repair", path: "/repair" },
  ];
  if (id) {
    tabs.push({ name: "Product Details", path: `/product/${id}` });
  }

  return (
    <div className="flex w-[195px] h-[35px] sm:block md:block lg:block bg-gray-800 text-sm justify-between rounded-full md:w-[225px] md:h-[38px]">
      {tabs.map((tab) => {
        const isActive = selectedTab === tab.name;

        return (
          <Link
            key={tab.name}
            to={tab.path}
            onClick={() => handleTabClick(tab.name)}
          >
            <button
              className={`rounded-full w-[55px] -mx-[-1px] mt-[2px] h-[31px] md:w-[67px] md:mx-1 md:mt-1 md:h-[30px] md:text-sm text-xs focus:outline-none transition-all ${isActive ? "bg-white text-black" : "text-white"
                }`}
            >
              {tab.name}
            </button>
          </Link>
        );
      })}
    </div>
  );
};

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Buy");
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isShoppingBagModalOpen, setIsShoppingBagModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showQueryForm, setShowQueryForm] = useState(false);
  const menuRef = useRef(null);
  const suggestionsRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    const user = localStorage.getItem("user");
    setIsSignedUp(!!user);

    const savedTab = localStorage.getItem("selectedTab") || "Buy"; // Set default tab if none is found
    setSelectedTab(savedTab);

    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
        setSuggestions([]);
        setSearchQuery('');
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setSelectedTab("Buy");
        localStorage.setItem("selectedTab", "Buy");
        break;
      case "/sell":
        setSelectedTab("Sell");
        localStorage.setItem("selectedTab", "Sell");
        break;
      case "/repair":
        setSelectedTab("Repair");
        localStorage.setItem("selectedTab", "Repair");
        break;
      case "/product": // Handle /product route
      case `/product/${id}`: // Handle /product/:id route
        setSelectedTab("Products");
        localStorage.setItem("selectedTab", "Buy");
        break;
      default:
        setSelectedTab("Buy"); // Default case
        break;
    }
  }, [location.pathname, id]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    localStorage.setItem("selectedTab", tab);
  };

  const handleProfile = () => {
    setIsProfileModalOpen(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const handleSignup = () => {
    setIsSignedUp(true);
    localStorage.setItem("user", "true");
    setIsProfileModalOpen(false);
  };

  const handleSearchButtonClick = (event) => {
    event.preventDefault();
    setShowQueryForm(true);
  };

  const handleShoppingBag = () => {
    if (isSignedUp) {
      setIsShoppingBagModalOpen(true);
    } else {
      setIsProfileModalOpen(true);
    }
  };

  const closeShoppingBagModal = () => {
    setIsShoppingBagModalOpen(false);
  };

  const closeSignInModal = () => {
    setIsSignInModalOpen(false);
  };

  const handleWishlist = () => {
    if (isSignedUp) {
      navigate("/wishlist");
    } else {
      setIsProfileModalOpen(true);
    }
  };

  const handleSearchInputChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query.length > 2) {
      try {
        const response = await axios.post(
          `${apiUrl}/api/product/search?t=${query}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = response.data;
        if (response.status === 200) {
          setSuggestions(data.suggestions);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }

  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/search?query=${searchQuery}`);
    setIsMenuOpen(false); // Close the menu on search submit
    setShowQueryForm(false); // Hide query form after submission

  };

  useEffect(() => {
    setIsMenuOpen(false); // Close the menu on page navigation
  }, [navigate]);

  return (
    <>
      <div>
        <div className="bg-white h-[51px] flex relative items-center justify-between px-4 md:px-12 gap-2 border-b border-black-500 z-50">
          <button className="text-lg md:hidden" onClick={toggleMenu}>
            <GiHamburgerMenu />
          </button>
          <div className="flex items-center gap-4">
            <Link to="/">
              <img src="assets/black-logo-1.svg" alt="I-Galaxy" />
            </Link>
            <TabSelector
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
              id={id}
            />
          </div>
          {/* Conditionally render links and search bar based on the current path */}
          {!["/sell", "/repair"].includes(location.pathname) && (
            <>
              <div className="hidden lg:hidden md:hidden text-[14px] items-start font-thin overflow-clip">
                <Link to="/Shop/Mobile" className="mr-3">
                  Phones
                </Link>
                <Link to="/Shop/Watch" className="mr-3">
                  Watches
                </Link>
                <Link to="/Shop/Tablet" className="mr-3">
                  Tablets
                </Link>
                <Link to="/Shop/laptop" className="mr-3">
                  Laptops
                </Link>
                <Link to="/" className="">
                  Pre-owned Devices
                </Link>
              </div>
            </>
          )}
          <div className="flex gap-6">
            <button
              className="hidden md:block text-md"
              onClick={handleWishlist}
            >
              <FaRegHeart />
            </button>
            <button
              className="hidden md:block text-xl mb-1"
              onClick={handleShoppingBag}
            >
              <HiOutlineShoppingBag />
            </button>
            <button className="hidden md:block text-lg mb-1" onClick={handleProfile}>
              <RiUserLine />
            </button>
          </div>
        </div>
        {/* Code for the large desktop version */}
        <div className="relative hidden md:flex lg:flex h-[52px] border-b justify-between items-center bg-white drop-shadow-md">
          <div className="flex gap-12 pl-10">
            {/* Conditionally render links for large desktop version */}
            {!["/sell", "/repair"].includes(location.pathname) && (
              <>
                <Link className="lg:text-black p-3 hover:border-b-2 hover:border-black text-md" to="/Shop/Mobile">
                  Phones
                </Link>
                <Link className="lg:text-black p-3 hover:border-b-2 hover:border-black text-md" to="/Shop/Watch">
                  Watches
                </Link>
                <Link className="lg:text-black p-3 hover:border-b-2 hover:border-black text-md" to="/Shop/Tablet">
                  Tablets
                </Link>
                <Link className="lg:text-black p-3 hover:border-b-2 hover:border-black text-md" to="/Shop/Laptop">
                  Laptop
                </Link>
              </>
            )}
          </div>

          {!["/sell", "/repair"].includes(location.pathname) && (
            <>
              <div className="relative z-30">
                <form
                  className="order-first mb-10 md:mb-0 md:order-last md:pr-8 relative"
                  onSubmit={handleSearchSubmit}
                >
                  <input
                    className="w-full lg:w-[498px] bg-white md:bg-gray-100 mr-5 py-[6px] pl-3 pr-10 rounded-full focus:outline-1"
                    type="text"
                    placeholder="Search.."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <button className="-ml-12 bg-transparent" type="submit">
                    <MdSearch className="mt-2" />
                  </button>
                  {searchQuery.length > 2 && suggestions.length > 0 && (
                    <div ref={suggestionsRef} className="absolute z-30 mt-1 w-full bg-white border border-gray-300 rounded-xl shadow-sm">
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="p-3 relative hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setSuggestions([]);
                            setSearchQuery('');
                            navigate(`/search?query=${suggestion.product_name}`);
                          }}
                        >
                          {suggestion.product_name} ({suggestion.company_name})
                        </div>
                      ))}
                    </div>
                  )}
                </form>
              </div>
            </>
          )}
        </div>
        <div>
          {/* mobile section */}
          <nav
            ref={menuRef}
            className={`fixed top-0 left-0 w-64 h-full bg-white z-50 transition-transform transform ${isMenuOpen ? "translate-x-0" : "-translate-x-full"
              } md:hidden`}
          >
            <div className="flex flex-col p-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Menu</h2>
                <button onClick={toggleMenu}>
                  <IoIosClose className="text-2xl" />
                </button>
              </div>
              <form className="relative mb-4" onSubmit={handleSearchSubmit}>
                <input
                  className="w-full z-50 bg-gray-100 py-[6px] pl-3 pr-10 rounded-full focus:outline-none"
                  type="text"
                  placeholder="Search.."
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <button
                  className="absolute right-0 top-0 mt-2 mr-3"
                  type="submit"
                >
                  <MdSearch />
                </button>
                {searchQuery.length > 2 && suggestions.length > 0 && (
                  <div className="absolute z-50 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-100 z-50 cursor-pointer"
                        onClick={() => {
                          setSearchQuery(suggestion.product_name);
                          setSuggestions([]);
                          navigate(`/search?query=${suggestion.product_name}`);
                        }}
                      >
                        {suggestion.product_name} ({suggestion.company_name})
                      </div>
                    ))}
                  </div>
                )}
              </form>
              <div className="flex flex-col gap-2">
                {/* Conditionally render links for mobile version */}
                {!["/sell", "/repair"].includes(location.pathname) && (
                  <>
                    <Link to="/Shop/Mobile" className="py-1 text-lg">
                      Phones
                    </Link>
                    <Link to="/Shop/Watch" className="py-1 text-lg">
                      Watches
                    </Link>
                    <Link to="/Shop/Tablet" className="py-1 text-lg">
                      Tablets
                    </Link>
                    <Link to="/Shop/Laptop" className="py-1 text-lg">
                      Laptops
                    </Link>
                    <Link to="/" className="py-1 text-lg">
                      Pre-owned Devices
                    </Link>
                  </>
                )}
                <button onClick={handleWishlist} className="mt-2 text-lg flex">
                  Wishlist
                </button>
              </div>
              <div className="mt-4">
                <button
                  onClick={handleShoppingBag}
                  className="py-1 w-full text-left"
                >
                  Shopping Bag
                </button>
                {/* <button
                  onClick={handleProfile}
                  className="py-2 w-full text-left"x 
                >
                  Profile
                </button>  */}
              </div>
              <div>
                <Profile1 />
              </div>
            </div>
          </nav>
        </div>
        <Modal
          isOpen={isProfileModalOpen}
          onRequestClose={closeProfileModal}
          contentLabel="Profile Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <Profile onClose={closeProfileModal} onSignup={handleSignup} />
        </Modal>

        <Modal
          isOpen={isShoppingBagModalOpen}
          onRequestClose={closeShoppingBagModal}
          contentLabel="Shopping Bag Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <ShoppingBag onClose={closeShoppingBagModal} />
        </Modal>
      </div>
    </>
  );
};

export default Navbar;