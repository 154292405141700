import React from 'react'

export default function Brands() {
  return (
    <div>
      <div className="px-6 md:px-24">
  <h1 className="font-semibold lg:mx-20 text-2xl mb-6">Brands We Accept</h1>
  <div className="p-4 md:p-10">
    <div className="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-8 gap-4 justify-items-center">
      <img src="assets/branding/apple.png" className="h-16 mt-2 w-auto" alt="Apple" />
      <img src="assets/branding/samsung.png" className="h-24 w-auto" alt="Samsung" />
      <img src="assets/branding/nothing.png" className="h-24 w-auto" alt="Nothing" />
      <img src="assets/branding/oneplus.png" className="h-24 w-auto" alt="OnePlus" />
      <img src="assets/branding/mi.png" className="h-16 mt-3 w-auto" alt="Mi" />
      <img src="assets/branding/realme.png" className="h-24 w-auto" alt="Realme" />
      <img src="assets/branding/oppo.png" className="h-24 w-auto" alt="Oppo" />
      <img src="assets/branding/google.png" className="h-16 mt-3 w-auto" alt="Google" />
      <img src="assets/branding/motorola.png" className="h-24 w-auto" alt="Motorola" />
      <img src="assets/branding/vivo.png" className="h-24 w-auto" alt="Vivo" />
      <img src="assets/branding/poco.png" className="h-24 w-auto" alt="Poco" />
      <img src="assets/branding/infinix.png" className="h-24 w-auto" alt="Infinix" />
      <img src="assets/branding/iquoo.png" className="h-24 w-auto" alt="iQOO" />
      <img src="assets/branding/honor.png" className="h-24 w-auto" alt="Honor" />
      <img src="assets/branding/lenovo.png" className="h-24 w-auto" alt="Lenovo" />
      <img src="assets/branding/nokia.png" className="h-24 w-auto" alt="Nokia" />
    </div>
  </div>
</div>

    </div>
  )
}
