import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Signup = ({ onClose, onSignup }) => {
  const [otpSent, setOtpSent] = useState(false);
  const [contact, setContact] = useState('');
  const [name, setName] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSendOtp = async () => {
    if (contact && name) {
      const isEmail = contact.includes('@');
      if(name.length > 50) {
        setError('Name must be less than 50 characters')
        return;
      }
      if (!isEmail) {
        if (contact.length !== 10 || isNaN(contact)) {
          setError('Please provide a valid 10-digit mobile number or email');
          return;
        }
      } 
      setLoading(true);
      setError('');
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const isEmail = contact.includes('@');
        const data = isEmail ? { name, email: contact } : { name, contactNumber: contact };

        const response = await axios.post(`${apiUrl}/api/users/`, data);
        console.log('Response from server:', response.data);
        setOtpSent(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error sending OTP:', error.response ? error.response.data : error.message);
        setError('Failed to send OTP');
      }
    } else {
      setError('Please provide a valid name and contact information');
    }
  };

  const handleVerifyOtp = async () => {
    if (otp) {
      setLoading(true);
      setError('');
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const isEmail = contact.includes('@');
        const data = isEmail ? { email: contact, otp, name } : { contactNumber: contact, otp, name };

        const response = await axios.post(`${apiUrl}/api/users/verify-otp`, data);
        console.log('OTP Verified:', response.data);

        setLoading(false);

        fetchUserData(contact, isEmail);

      } catch (error) {
        console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
        setLoading(false);
        setError('Invalid OTP');
      }
    }
  };

  const fetchUserData = async (contact, isEmail) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = isEmail ? `${apiUrl}/api/users/getUsers/${contact}` : `${apiUrl}/api/users/getUsers/${contact}`;

      const response = await axios.get(url);

      if (!response.data) {
        throw new Error('User not found');
      }

      onSignup(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleResendOtp = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const isEmail = contact.includes('@');
      const data = isEmail ? { email: contact } : { contactNumber: contact };

      await axios.post(`${apiUrl}/api/users/`, data);
    } catch (error) {
      setError('Failed to resend OTP');
    }
  };

  const handleChangeContact = () => {
    setOtpSent(false);
    setContact('');
    setOtp('');
  };

  const handleChangeName = () => {
    setOtpSent(false);
    setName('');
    setOtp('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      otpSent ? handleVerifyOtp() : handleSendOtp();
    }
  };

  return (
    <div className="py-2">
      <div className="text-left">
        <div className="flex justify-between items-center">
          <p className="mt-2 text-[18px] font-normal text-black sm:text-2xl">
            Create an account on iGalaxy
          </p>
          <button
            type="button"
            className="text-black focus:outline-none"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <p className="mt-1 text-base leading-7 text-gray-600 text-[20px] sm:text-[25px]">
          Sign up with your mobile number or email to get started
        </p>

        {error && <p className="text-red-500">{error}</p>}

        <div className="mt-6 flex justify-between items-center">
          <p className="text-[17px] font-normal text-black">
            Name*
          </p>
          {otpSent && (
            <button
              type="button"
              className="text-blue-700 font-normal text-base focus:outline-none"
              onClick={handleChangeName}
            >
              Change
            </button>
          )}
        </div>
        <input
          type="text"
          placeholder="Enter Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-2 w-full p-2 border border-gray-300 rounded-md"
          disabled={otpSent}
          onKeyDown={handleKeyDown} // Adding keydown event for Enter key
        />

        <div className="mt-6 flex justify-between items-center">
          <p className="text-[17px] font-normal text-black">
            Email or Mobile Number*
          </p>
          {otpSent && (
            <button
              type="button"
              className="text-blue-700 font-normal text-base focus:outline-none"
              onClick={handleChangeContact}
            >
              Change
            </button>
          )}
        </div>
        <input
          type="text"
          placeholder="Enter Mobile Number or Email"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          className="mt-2 w-full p-2 border border-gray-300 rounded-md"
          disabled={otpSent}
          onKeyDown={handleKeyDown} // Adding keydown event for Enter key
        />

        {otpSent && (
          <>
            <div className="mt-6 flex justify-between items-center">
              <p className="text-[17px] font-normal text-black">
                Enter OTP*
              </p>
              <button
                type="button"
                className="text-blue-700 font-normal text-base focus:outline-none"
                onClick={handleResendOtp}
              >
                Resend
              </button>
            </div>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="mt-2 w-full p-2 border border-gray-300 rounded-md"
              onKeyDown={handleKeyDown} // Adding keydown event for Enter key
            />
          </>
        )}

        <div className="mt-6 flex flex-col sm:flex-row gap-4">
          <button
            type="button"
            className="inline-flex justify-center rounded-full px-3 py-2 text-white bg-blue-700 w-full sm:w-auto"
            onClick={otpSent ? handleVerifyOtp : handleSendOtp}
            disabled={loading}
          >
            {loading ? 'Processing...' : otpSent ? 'Verify OTP' : 'Send OTP'}
          </button>
          <div className='flex gap-2 items-center'>
            <p className="text-gray-600">
              Existing user?
            </p>
            <button
              type="button"
              className="text-blue-700 font-normal text-base focus:outline-none"
              onClick={() => onClose(false)}
            >
              Signin
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SignupPopup = ({ onClose, onSignup, onLogin }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#6B7280] bg-opacity-50">
      <div className="bg-white p-4 sm:p-[50px] rounded-[20px] shadow-md border w-full sm:w-[500px] sm:h-[600px] h-full relative">
        <Signup onClose={onClose} onSignup={onSignup} onLogin={onLogin} />
      </div>
    </div>
  );
};

export default SignupPopup;
