import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { FaAngleRight, FaAngleDown, FaMapMarkerAlt } from 'react-icons/fa';
import Profile1 from "./Profile1";
import AddAddress from './AddAdress';
import { RiDeleteBin5Line } from "react-icons/ri";
import 'react-toastify/dist/ReactToastify.css';

const Address = () => {
  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const fetchAddresses = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        console.log("User not logged in");
        return;
      }

      if (!user.email && !user.contactNumber) {
        console.log("User details incomplete");
        return;
      }
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${apiUrl}/api/users/getUserAddresses`, {
        params: {
          email: user.email,
          contactNumber: user.contactNumber
        }
      });

      setAddresses(response.data.addresses);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      //toast.error("Failed to fetch addresses");
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const handleOpenAddress = (address = null) => {
    setCurrentAddress(address);
    setIsAddressOpen(true);
  };

  const handleCloseAddress = () => {
    setIsAddressOpen(false);
    setCurrentAddress(null);
    fetchAddresses();
  };

  const handleRemoveAddress = async (addressId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const user = JSON.parse(localStorage.getItem('user'));
      await axios.post(`${apiUrl}/api/users/removeAddress`, {
        email: user.email,
        contactNumber: user.contactNumber,
        addressId
      });
      toast.success("Address removed successfully");
      fetchAddresses();
    } catch (error) {
      console.error("Error removing address:", error);
      toast.error("Failed to remove address");
    }
  };

  const toggleAddressDetails = (addressId) => {
    setSelectedAddressId((prevAddressId) => (prevAddressId === addressId ? null : addressId));
  };

  const AddressItem = ({ address }) => {
    return (
      <div key={address._id} className="rounded-lg border-b-2 p-4 flex flex-col w-full gap-4 cursor-pointer">
        <div className="flex items-center justify-between" onClick={() => toggleAddressDetails(address._id)}>
          <div className="flex flex-col">
            <p className="text-lg font-semibold">{address.name}</p>
            <p className="text-sm text-gray-600">{address.mobileNumber}</p>
            <p className="text-sm text-gray-600">{address.houseNo} {address.fullAddress}</p>
            <p className="text-sm text-gray-600">{address.pincode}, {address.city}, {address.state}</p>
          </div>
          {selectedAddressId === address._id ? (
            <FaAngleDown className="text-gray-600" />
          ) : (
            <FaAngleRight className="text-gray-600" />
          )}
        </div>
        {selectedAddressId === address._id && (
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white px-3 py-1 rounded-full flex items-center"
              onClick={() => handleRemoveAddress(address._id)}
            >
              <RiDeleteBin5Line className="mr-2" /> Remove
            </button>
            <button
              className="bg-yellow-500 text-white px-3 py-1 rounded-full"
              onClick={() => handleOpenAddress(address)}
            >
              Update
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="mt-7 flex items-center md:ml-16 justify-center p-4">
        <div className="flex gap-5 flex-col md:flex-row w-full">
          <Profile1 />
          <div className="w-full md:w-[700px] lg:w-[900px] md:h-[500px] bg-white border-2 rounded-3xl flex flex-col">
            {addresses.length === 0 ? (
              <div className="text-center items-center justify-center p-4">
                <FaMapMarkerAlt className="w-16 h-16 mx-auto text-gray-400" />
                <p className="mt-2 text-2xl font-normal text-black">No Address added yet</p>
              </div>
            ) : (
              <div className="overflow-y-auto p-4 md:p-8 flex-1">
                <h2 className="text-3xl font-semibold mb-4">Your Addresses</h2>
                <div className="grid gap-4">
                  {addresses.map((address) => (
                    <AddressItem key={address._id} address={address} />
                  ))}
                </div>
              </div>
            )}
            <button
              className="mt-4 mb-5 bg-blue-800 text-white text-md px-5 py-2 mx-5 max-w-full rounded-full hover:bg-blue-700"
              onClick={() => handleOpenAddress()}
            >
              Add Address
            </button>
          </div>
        </div>
      </div>
      {isAddressOpen && <AddAddress onClose={handleCloseAddress} address={currentAddress} />}
      <ToastContainer />
    </>
  );
};

export default Address;
