import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Pagination, Autoplay } from "swiper/modules";

function Caraousel() {
  const navigate = useNavigate();
  const handleViewAll = () => {
    navigate(`/Store`); // Redirect to the category page based on the type
  };

  return (
    <>
      <div className="h-64 sm:h-80 md:h-96 lg:h-[500px]">
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide className="">
            <div className="h-64 sm:h-80 md:h-96 lg:h-[500px] relative">
              <img
                className="w-full h-full object-cover"
                src="assets/carousel2.webp"
                alt=""
              />
              <div className="absolute left-4 bottom-8 sm:bottom-20 sm:left-24">
                <h1 className="text-xl sm:text-2xl md:text-4xl lg:text-4xl font-bold text-white mb-2">
                  OPPO K12x 5G
                </h1>
                <p className="text-sm sm:text-lg md:text-xl lg:text-xl text-white">
                  Toughest 5G | Phone in the segment
                </p>
                <p className="text-sm sm:text-lg md:text-xl lg:text-xl mb-4 text-white">
                  From ₹12,999 only
                </p>
                <button
                  onClick={handleViewAll}
                  className="bg-blue-800 rounded-full px-8 py-2 sm:px-10 sm:py-3 md:px-14 md:py-3 text-sm sm:text-base md:text-lg lg:text-xl text-white"
                >
                  Buy Now
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="">
            <div className="h-64 sm:h-80 md:h-96 lg:h-[500px] relative">
              <img
                className="w-full h-full object-cover"
                src="assets/carousel3.webp"
                alt=""
              />
              <div className="absolute left-4 bottom-8 sm:bottom-20 sm:left-24">
                <h1 className="text-xl sm:text-2xl md:text-4xl lg:text-4xl font-bold text-black mb-2">
                  OPPO A3x 5G
                </h1>
                <p className="text-sm sm:text-lg md:text-xl lg:text-xl text-black mb-2">
                  Multiple Liquid Resistance
                </p>
                <p className="text-sm sm:text-lg md:text-xl lg:text-2xl mb-4 text-black">
                  From ₹12,999 only
                </p>
                <button
                  onClick={handleViewAll}
                  className="bg-blue-800 rounded-full px-8 py-2 sm:px-10 sm:py-3 md:px-14 md:py-3 text-sm sm:text-base md:text-lg lg:text-xl text-white"
                >
                  Buy Now
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="">
            <div className="h-64 sm:h-80 md:h-96 lg:h-[500px] relative">
              <img
                className="w-full h-full object-cover"
                src="assets/carousel1.webp"
                alt=""
              />
              <div className="absolute left-4 bottom-12 sm:bottom-20 sm:left-24">
                <h1 className="text-xl sm:text-xl md:text-3xl lg:text-4xl font-bold text-black mb-2">
                  MEMBERS'S MEGA SALE
                </h1>
                <p className="text-sm sm:text-lg md:text-xl lg:text-xl text-black">
                  Enjoy 8 Bonus Offers &{" "}
                  <span className="block sm:inline"> The Magic of AI</span>
                </p>

                <p className="text-sm sm:text-lg md:text-xl lg:text-2xl mb-4 text-black">
                  From August
                </p>
                <p className="text-sm sm:text-lg md:text-xl lg:text-2xl mb-4 text-black"></p>
                <button
                  onClick={handleViewAll}
                  className="bg-blue-800 rounded-full px-8 py-2 sm:px-10 sm:py-3 md:px-14 md:py-3 text-sm sm:text-base md:text-lg lg:text-xl text-white"
                >
                  Buy Now
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="">
            <div className="h-64 sm:h-80 md:h-96 lg:h-[500px] relative">
              <img
                className="w-full h-full object-cover"
                src="assets/carousel4.webp"
                alt=""
              />
              <div className="absolute left-4 bottom-8 sm:bottom-20 sm:left-24">
                <h1 className="text-xl sm:text-2xl md:text-4xl lg:text-4xl font-bold text-black mb-2">
                  OPPO F27 Pro+ 5G
                </h1>
                <p className="text-sm sm:text-sm md:text-xl lg:text-xl text-black">
                  India's First Waterproof
                  <span className="hidden sm:inline">
                    Rated IP69 SmartPhone
                  </span>
                </p>
                <p className="text-sm sm:text-lg md:text-xl lg:text-2xl mb-2 text-black">
                  Exchange Reno &{" "}
                  <span className="block sm:inline">F Series to New</span>
                </p>

                <p className="text-sm sm:text-lg md:text-xl lg:text-2xl mb-4 text-black">
                  From ₹27,999 only
                </p>
                <button
                  onClick={handleViewAll}
                  className="bg-blue-800 rounded-full px-8 py-2 sm:px-10 sm:py-3 md:px-14 md:py-3 text-sm sm:text-base md:text-lg lg:text-xl text-white"
                >
                  Buy Now
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

export default Caraousel;
