import React, { useState } from 'react';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import Brands from '../components/categories/Brands';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Callus from '../components/common/Callus';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mt-5">
      <div
        className="font-semibold border-b-2 p-2 pb-4 cursor-pointer flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </div>
      {isOpen && <p className="mt-2">{answer}</p>}
    </div>
  );
};

function Repair() {
  const [isCallusOpen, setIsCallusOpen] = useState(false);

  const phoneNumber = "+918961722385"; // Replace with the actual phone number

  // Detect if the user is on a mobile device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div>
      <div className='p-2 md:p-20 font-urbanist'>
        <div className="bg-gray-100 lg:mx-20 p-8 rounded-xl flex flex-col md:flex-row items-center justify-between">
          <div className="max-w-lg mb-8 md:mb-0">
            <h2 className="text-5xl font-semibold mb-4">Repair Your Phone At Your Doorstep</h2>
            <p className="mb-6">Fill the form and our team will reach out to you or Call us directly</p>
            <div className="space-x-4">
              <a
                href="https://forms.gle/oXQHpyacd3RoXnkg7"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-800 text-white px-6 py-2 md:w-1/3 rounded-full shadow-md hover:bg-blue-700 focus:outline-none"
              >
                Fill a Form
              </a>
              {isMobile ? (
                <a href={`tel:${phoneNumber}`} className="border border-gray-400 text-gray-800 px-6 py-2 rounded-full shadow-md hover:bg-gray-200 focus:outline-none">
                  Call Us
                </a>
              ) : (
                <button onClick={() => setIsCallusOpen(true)} className="border border-gray-400 text-gray-800 px-6 py-2 rounded-full shadow-md hover:bg-gray-200 focus:outline-none">
                  Call Us
                </button>
              )}
            </div>
          </div>
          <div className="md:ml-8 bg-slate-100">
            <img src="assets/unnamed 1.svg" alt="Repair Phone" className="max-w-full bg-slate-100" />
          </div>
        </div>
      </div>
      <div><Brands /></div>
      <div className='md:ml-20 lg:mx-40'>
        <div className="container p-4">
          <h2 className="font-semibold text-2xl mb-14">How This Works?</h2>
          <div className="grid md:grid-cols-3 gap-8 lg:gap-16 mb-12 p-6">
            <div className="bg-gray-100 p-6 rounded-lg text-left">
              <img src="assets/checkprice.svg" alt="Check Price" className="mb-8 p-2" />
              <div>
                <h3 className="text-xl font-semibold mb-2">1. Check Price</h3>
                <p>Fill a form with the details asked about your mobile. Our team will tell you the cost to repair your mobile.</p>
              </div>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg text-left">
              <img src="assets/scheduleprice.jpg" alt="Schedule Service" className="mb-8 p-2" />
              <h3 className="text-xl font-semibold mb-2">2. Schedule Service</h3>
              <p>Choose a date and time when you like our team for the service.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg text-left">
              <img src="assets/getrepair.svg" alt="Get Device Repaired" className="mb-8 p-2" />
              <h3 className="text-xl font-semibold mb-2">3. Get Device Repaired</h3>
              <p>Our super-skilled technician will be there and make your phone as good as new.</p>
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-10">FAQs</h2>
            <div className="space-x-2 grid grid-cols-1 md:grid-cols-2">
              <FAQItem
                question="What type of Mobile Phone repair does iGalaxy Offer?"
                answer="iGalaxy offers different kinds of mobile phone repair: cracked screen, water damage, battery drain, mic issue, and much more."
              />
              <FAQItem
                question="Can I get my mobile phone repaired online?"
                answer="Details regarding online repair services..."
              />
              <FAQItem
                question="Does iGalaxy use genuine parts for repair?"
                answer="Information about the quality of parts used..."
              />
              <FAQItem
                question="How much would a repair cost me?"
                answer="Details about repair costs..."
              />
            </div>
          </div>
        </div>
      </div>
      {isCallusOpen && <Callus isOpen={isCallusOpen} onClose={() => setIsCallusOpen(false)} />}
    </div>
  );
}

export default Repair;
