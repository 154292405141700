import React from 'react'
import Marquee from "react-fast-marquee";
import { PiStarFourFill } from "react-icons/pi";

export default function Slidingtext() {
  return (
    <div className=' text-white'>
      <Marquee autoFill={true}>
        <p className='px-10 py-3'>Get Instant 10% Discount on HDFC Credit Cards!</p>
        <p className=''><PiStarFourFill className='' /></p>
      </Marquee>
    </div>
  )
}
