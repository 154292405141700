import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { RingLoader } from 'react-spinners';

const Cart = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [totals, setTotals] = useState({ subtotal: 0, discount: 0, delivery: 0, tax: 0, total: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCartDetails = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
          console.log("User not logged in");
          navigate("/login");
          return;
        }

        //console.log("User details:", user);

        if (!user.email && !user.contactNumber) {
          console.log("User details incomplete");
          navigate("/login");
          return;
        }
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/api/users/carts`, {
          params: {
            email: user.email,
            contactNumber: user.contactNumber
          }
        });

        console.log("API response:", response.data);

        const { cartItems, totals } = response.data;
        setItems(cartItems);
        setTotals(totals);
      } catch (error) {
        console.error("Error fetching cart details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCartDetails();
  }, [navigate]);

  const updateItemQuantity = async (productId, selectedColor, selectedStorage, newQuantity) => {
    if (newQuantity <= 0) {
      console.error('Quantity must be greater than 0');
      return;
    }
  
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const apiUrl = process.env.REACT_APP_API_URL;
  
      // Log the payload being sent
      console.log('Updating item quantity with payload:', {
        email: user.email,
        contactNumber: user.contactNumber,
        productId,
        selectedColor,
        selectedStorage,
        quantity: newQuantity
      });
  
      const response = await axios.post(`${apiUrl}/api/users/addToCart`, {
        email: user.email,
        contactNumber: user.contactNumber,
        productId,
        selectedColor,
        selectedStorage,
        quantity: newQuantity
      });
  
      // Log the API response
      console.log('API response:', response.data);
  
      const { cartItems, totals } = response.data;
      setItems(cartItems);
      setTotals(totals);
  
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };
  



  const deleteItem = async (productId, selectedColor, selectedStorage) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await axios.post(`${apiUrl}/api/users/remove`, {
        email: user.email,
        contactNumber: user.contactNumber,
        productId,
        selectedColor,
        selectedStorage
      });

      //console.log('Updating item:', { productId, selectedColor, selectedStorage});

      const updatedCartItems = response.data;
      setItems(updatedCartItems);

      const newTotals = updatedCartItems.reduce((acc, item) => {
        acc.subtotal += item.price;
        acc.discount += item.price - item.discounted_price;
        return acc;
      }, { subtotal: 0, discount: 0, delivery: 5.00, tax: 0, total: 0 });

      newTotals.tax = (newTotals.subtotal - newTotals.discount) * 0.1;
      newTotals.total = (newTotals.subtotal - newTotals.discount + newTotals.tax) + newTotals.delivery;

      setTotals(newTotals);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleCheckout = () => {
    navigate('/checkout', { state: { totalAmount: totals.total, cartItems: items } });
  };

  const handleContinueShopping = () => {
    navigate(-2); // Navigate two pages back in history
  };

  const calculateExpectedDeliveryDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 3);
    return currentDate.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  const CartItem = ({ item }) => {
    const handleIncrement = () => {
      const colorCode = item.colorCode || ''; // Provide a default value if necessary
      const storageCode = item.storageCode || ''; // Provide a default value if necessary
      updateItemQuantity(item.product, colorCode, storageCode, item.quantity + 1);
    };

    const handleDecrement = () => {
      if (item.quantity >= 1) {
        const colorCode = item.colorCode || ''; // Provide a default value if necessary
        const storageCode = item.storageCode || ''; // Provide a default value if necessary
        updateItemQuantity(item.product, colorCode, storageCode, item.quantity - 1);
      }
    };


    console.log("CartItem:", item);

    const imageUrl = item.images && item.images[item.colorCode] ? item.images[item.colorCode] : item.image;

    return (
      <div className="flex mb-6 p-2 pb-4 border-b font-urbanist">
        <img src={imageUrl} alt={item.name} className="w-24 h-24 mr-4" />
        <div className="flex flex-col justify-between w-full">
          <div className="flex justify-between">
            <h4 className="text-xl font-semibold line-clamp-2">{item.name}</h4>
            <h4 className="text-xl flex flex-col text-right font-semibold">₹{item.discounted_price}<span className='font-thin line-through text-sm text-gray-500'>₹{item.price}</span></h4>
          </div>
          <p className="text-md md:mt-0 lg:-mt-1 mb-2 text-gray-700">Standard Delivery by {calculateExpectedDeliveryDate()} | Free</p>
          <div className="mt-2 flex items-center justify-between">
            <div>
              <button onClick={handleDecrement} className="px-2 h-8 w-8 py-1 border border-black rounded-full">-</button>
              <span className="mx-4">{item.quantity}</span>
              <button onClick={handleIncrement} className="px-2 h-8 w-8 py-1 border border-black rounded-full">+</button>
            </div>
            <div>
              <button onClick={() => deleteItem(item.product, item.colorCode, item.storageCode)} className="text-red-500 hover:text-red-700">Delete</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CartSummary = () => (
    <div className=''>
      <div className="p-4 border text-md rounded-3xl">
        <h3 className="text-xl font-semibold mb-4">Cart Summary</h3>
        <div className=''>
          <p className='flex justify-between'>Item Subtotal: <span>₹{totals.subtotal.toFixed(2)}</span></p>
          <p className='flex justify-between'>Discount: <span> - ₹{totals.discount}</span></p>
          <p className='flex justify-between'>Delivery: <span>FREE</span></p>
          <p className='flex justify-between'>TAX : <span>Included</span></p>
          {/* <p className='flex justify-between'>Delivery: <span>₹{totals.delivery.toFixed(2)}</span></p>
          <p className='flex justify-between'>TAX : <span>₹{totals.tax.toFixed(2)}</span></p> */}
        </div>
        <p className='font-semibold text-lg border-b-2 pb-4 flex justify-between'>Total Amount: <span>₹{totals.total.toFixed(2)}</span></p>
        <button className="w-full py-3 rounded-full mt-4 bg-blue-700 text-white hover:bg-blue-700" onClick={handleCheckout}>Checkout</button>
      </div>
      <div className='flex gap-12 text-center text-sm md:text-md mt-5'>
        <div className=""><img className='m-auto' src="assets/30daysexchange.svg" alt="" /><p className=''>30 Days Exchange</p></div>
        <div className='w-1/3'><img className='m-auto' src="assets/12dayswarranty.svg" alt="" /><p className=''>12 Months Warranty</p></div>
        <div className='w-1/3'><img className='m-auto' src="assets/security.svg" alt="" /><p className=''>Secure and Safe Payments</p></div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-100 z-50">
        <RingLoader color="#82558a" size={60} />
      </div>
    );
  }

  if (items.length === 0) {
    return (
      <div className="p-8">
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold">Your Cart</h1>
          <p className="mt-2">Your cart is empty</p>
        </header>
      </div>
    );
  }

  return (
    <div className="p-4 px-10 mt-12 font-urbanist">
      <div className="flex flex-col md:flex-row gap-8 md:gap-16">
        <div className='md:w-2/3'>
          <header className="text-center md:text-left mb-4">
            <h1 className="text-3xl font-bold">Your Cart</h1>
            <div className="flex justify-between items-center mt-2">
              <p>You have {items.length} items in your cart</p>
              <button
                className="bg-white-500 text-blue-700 py-2 px-4 rounded-md"
                onClick={handleContinueShopping}
              >
                Continue shopping
              </button>
            </div>
          </header>
          <div className=''>
            {items.map((item, index) => (
              <CartItem key={index} item={item} />
            ))}
          </div>
        </div>
        <CartSummary />
      </div>
    </div>
  );
};

export default Cart;