import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

class Catcard extends Component {
  render() {
    const categories = [
      { name: "Mobile", image: "/assets/smartphone.svg" },
      { name: "Watch", image: "/assets/watches.svg" },
      { name: "Pre-Owned", image: "/assets/Preowned.svg" },
      { name: "Tablet", image: "/assets/tablets.svg" },
      { name: "Laptop", image: "/assets/laptop.svg" },
      { name: "Earphone", image: "/assets/headphones.svg" },
      { name: "TV", image: "/assets/Tv.svg" },
      { name: "Soundbar", image: "/assets/soundbar.svg" },
      { name: "Gaming", image: "/assets/gamming.svg" },
      { name: "Camera", image: "/assets/camera.svg" },
    ];

    return (
      <div className="md:p-4 md:h-[237px] mt-4 align-middle content-center md:px-4 bg-gray-50">
        <div className="grid grid-cols-4 md:flex md:px-8 overflow-x-auto whitespace-nowrap justify-between no-scrollbar">
          {categories.map((category, index) => (
            <div className='text-center' key={index}>
              <Link
                to={`/Shop/${category.name}`}
                className="inline-block bg-white rounded-full w-20 md:h-[120px] md:w-[120px] flex-shrink-0 m-2 text-center content-center justify-center"
              >
                <img src={category.image} alt={category.name} className="w-16 mt-1 h-16 md:w-20 md:h-20 object-contain mb-2 mx-auto" />
              </Link>
              <p className="text-sm">{category.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Catcard;
