import React from 'react';

const Invoice1 = ({ invoiceData }) => {
  const {
    logoSrc,
    companyName,
    companyAddress,
    companyContact,
    gstNumber,
    customerName,
    customerAddress,
    customerPhone,
    billNumber,
    invoiceDate,
    items,
    totalItems,
    totalQuantity,
    grossAmount,
    discountAmount,
    netAmount,
    amountInWords,
    paymentMethod,
    bankDetails,
  } = invoiceData;

  return (
    <div className="max-w-4xl mx-auto p-4 border border-gray-300 shadow-md">
      {/* Header */}
      <div className="flex justify-between items-center border-b pb-4 mb-4">
        <div className="flex items-center">
          <img src={logoSrc} alt={`${companyName} Logo`} className="w-40 h-24 mr-4" />
          <div className="text-center ml-24">
            <h1 className="text-xl font-bold">{companyName}</h1>
            <p>{companyAddress}</p>
            <p>PH: {companyContact}</p>
          </div>
        </div>
        <div>
          <p className="text-right font-bold">GST: {gstNumber}</p>
        </div>
      </div>

      {/* Customer Details */}
      <div className="grid grid-cols-2 gap-4 border-b pb-4 mb-4">
        <div>
          <p>TO: {customerName}</p>
          <p>ADDRESS: {customerAddress}</p>
          <p>PH: {customerPhone}</p>
        </div>
        <div className="text-right">
          <p>Bill No: {billNumber}</p>
          <p>Date: {invoiceDate}</p>
        </div>
      </div>

      {/* Items Table */}
      <div className="border-b pb-4 mb-4">
        <table className="w-full text-sm">
          <thead>
            <tr>
              <th className="border p-2 font-semibold">S.No</th>
              <th className="border p-2 font-semibold">Items</th>
              <th className="border p-2 font-semibold">HSN Code</th>
              <th className="border p-2 font-semibold">Qty</th>
              <th className="border p-2 font-semibold">Mrp</th>
              <th className="border p-2 font-semibold">Rate</th>
              <th className="border p-2 font-semibold">Amount</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td className="border p-2">{index + 1}</td>
                <td className="border p-2">
                  {item.name}
                  <br />
                  IMEI: {item.imei}
                </td>
                <td className="border p-2">{item.hsnCode}</td>
                <td className="border p-2">{item.quantity}</td>
                <td className="border p-2">{item.mrp}</td>
                <td className="border p-2">{item.rate}</td>
                <td className="border p-2">{item.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Summary */}
      <div className="flex justify-between mb-4">
        <div className="w-1/2">
          <p>Tot. Items: {totalItems}</p>
          <p>Tot. Qty: {totalQuantity}</p>
          <p>AMOUNT IN WORDS</p>
          <p className="font-bold">{amountInWords}</p>
          <p>Payment Method: {paymentMethod}</p>
          <p className="text-xs mt-4">
            *Goods once sold will not be exchanged or returned.
            <br />
            *Service will be provided by Authorised Service Center only.
          </p>
        </div>
        <div className="w-1/3">
          <table className="w-full text-sm">
            <tbody>
              <tr>
                <td className="p-2 font-semibold">Gross Amount :</td>
                <td className="p-2">{grossAmount}</td>
              </tr>
              <tr>
                <td className="p-2 font-semibold">Tot Disc Amt :</td>
                <td className="p-2">{discountAmount}</td>
              </tr>
              <tr>
                <td className="p-2 font-semibold">Net Amount :</td>
                <td className="p-2 font-bold">{netAmount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Bank Details */}
      <div className="border-t pt-4">
        <p>BANK DETAILS:</p>
        <p>BANK: {bankDetails.bankName}</p>
        <p>ACC No.: {bankDetails.accountNumber}</p>
        <p>IFSC Code: {bankDetails.ifscCode}</p>
        <p>Address: {bankDetails.bankAddress}</p>
      </div>
    </div>
  );
};

// Example props data
const invoiceData = {
  logoSrc: "assets/black-logo-1.svg",
  companyName: "iGALAXY",
  companyAddress: "3, DR BIRESH GUHA STREET, KOLKATA-700017",
  companyContact: "953127235 / 9820066786",
  gstNumber: "19AAJFI2338A1ZN",
  customerName: "RAMZI RAJA",
  customerAddress: "#11/4H JAMNAGAR",
  customerPhone: "7435960224",
  billNumber: "CA/575",
  invoiceDate: "05/08/2024",
  items: [
    {
      name: "Iphone 15 blue, 128gb",
      imei: "35572818036236, 355728180523160",
      hsnCode: "",
      quantity: 1,
      mrp: "79900.00",
      rate: "79900.00",
      amount: "67000.00",
    },
  ],
  totalItems: "1.00",
  totalQuantity: 1,
  grossAmount: "67000.00",
  discountAmount: "12900.00",
  netAmount: "67000.00",
  amountInWords: "Rupees Sixty Seven Thousand Only",
  paymentMethod: "CASH, CREDIT CARD, UPI, FINANCE, CREDIT",
  bankDetails: {
    bankName: "ICICI BANK",
    accountNumber: "77770620488",
    ifscCode: "ICIC0002942",
    bankAddress: "198 BROAD STREET, KOLKATA-700019",
  },
};

const App = () => {
  return <Invoice1 invoiceData={invoiceData} />;
};

export default App;