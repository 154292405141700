import React, { useState } from 'react';
import Brands from '../components/categories/Brands';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Callus from '../components/common/Callus';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mt-5">
      <div
        className="font-semibold border-b-2 p-2 pb-4 cursor-pointer flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </div>
      {isOpen && <p className="mt-2">{answer}</p>}
    </div>
  );
};

export default function Sell() {
  const [isCallusOpen, setIsCallusOpen] = useState(false);

  const phoneNumber = "+918961722385"; // Replace with the actual phone number

  // Detect if the user is on a mobile device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div>
      <div className='p-2 md:p-20 font-urbanist'>
        <div className="bg-gray-100 lg:mx-20 p-8 rounded-xl flex flex-col md:flex-row items-center justify-between">
          <div className="max-w-lg mb-8 md:mb-0">
            <h2 className="text-5xl font-semibold mb-4">Sell Your Mobile Phone For Instant Cash</h2>
            <p className="mb-6">Fill the form and our team will reach out to you or call us directly</p>
            <div className="space-x-4">
              <a
                href="https://forms.gle/oXQHpyacd3RoXnkg7"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-800 text-white px-6 py-2 md:w-1/3 rounded-full shadow-md hover:bg-blue-700 focus:outline-none"
              >
                Fill a Form
              </a>
              {isMobile ? (
                <a href={`tel:${phoneNumber}`} className="border border-gray-400 text-gray-800 px-6 py-2 rounded-full shadow-md hover:bg-gray-200 focus:outline-none">
                  Call Us
                </a>
              ) : (
                <button onClick={() => setIsCallusOpen(true)} className="border border-gray-400 text-gray-800 px-6 py-2 rounded-full shadow-md hover:bg-gray-200 focus:outline-none">
                  Call Us
                </button>
              )}
            </div>
          </div>
          <div className="md:ml-8">
            <img src="assets/SellPhone.svg" alt="Sell your Phone" className="max-w-full h-auto" />
          </div>
        </div>
      </div>
      <div><Brands /></div>
      <div className='md:ml-20 lg:mx-40'>
        <div className="container p-4">
          <h2 className="font-semibold text-2xl mb-14">How This Works?</h2>
          <div className="grid md:grid-cols-3 gap-16 mb-12 p-6">
            <div className="bg-gray-100 p-6 rounded-lg text-left">
              <img src="assets/checkprice.svg" alt="Check Price" className="mb-8 p-2" />
              <div>
                <h3 className="text-xl font-semibold mb-2">1. Check Price</h3>
                <p>Fill a form with the details asked about your mobile. Our team will tell your the cost to repair your mobile.</p>
              </div>
            </div>
            <div className="bg-gray-100 p-6  rounded-lg text-left">
              <img src="assets/checkprice.svg" alt="Schedule Service" className="mb-8" />
              <h3 className="text-xl font-semibold mb-2">2. Schedule Service</h3>
              <p>Book a free pickup from your home or work at a time slot that best suits your convenience.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg text-left">
              <img src="assets/getpaid.svg" alt="Get Device Repaired" className="mb-8" />
              <h3 className="text-xl font-semibold mb-2">3. Get Paid</h3>
              <p>Did we mention you get paid as soon as our executive picks up your device? It's instant payment all the way.</p>
            </div>
          </div>
          <div className=''>
            <h2 className="text-2xl font-semibold mb-10">FAQs</h2>
            <div className="space-x-2 grid grid-cols-1 md:grid-cols-2">
              <FAQItem
                question="Where can I turn in old phones for cash?"
                answer="In India there are many websites to sell your old mobile phone for cash but if your are looking for reliability then Cashify is most trusted platform to sell your mobile for instant cash."
              />
              <FAQItem
                question="How can I sell my old phone?"
                answer="Information about the quality of parts used..."
              />
              <FAQItem
                question="What do you do with my old phone?"
                answer="Information about the quality of parts used..."
              />
              <FAQItem
                question="How much would I get from my old phone?"
                answer="Details about repair costs..."
              />
            </div>
          </div>
        </div>
      </div>
      {isCallusOpen && <Callus isOpen={isCallusOpen} onClose={() => setIsCallusOpen(false)} />}
    </div>
  );
}
