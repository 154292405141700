import React, { Component } from 'react';

export class TermsConditions extends Component {
  render() {
    return (
      <>
        <section className="mx-auto max-w-6xl text-left p-8 md:p-20 py-10">
          <h1 className='text-3xl font-normal'>Terms and Conditions</h1>
          <div className='text-base mt-6'>
            <p>
              Welcome to igalaxy.co.in. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use.
            </p>
            <br />
            <p>
              The term ‘igalaxy’ or ‘us’ or ‘we’ refers to the owner of the website whose registered office is 3 Dr Biresh Guha Street, Kolkata 700017. The term ‘you’ refers to the user or viewer of our website.
            </p>
            <br />
            <h3 className='text-xl font-semibold'>The use of this website is subject to the following terms of use:</h3>
            <br />
            <p>
              The content of the pages of this website is for your general information and use only. It is subject to change without notice.
            </p>
            <br />
            <p>
              Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
            </p>
            <br />
            <p>
              Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products or information available through this website meet your specific requirements.
            </p>
            <br />
            <p>
              This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
            </p>
            <br />
            <p>
              All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.
            </p>
            <br />
            <p>
              Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.
            </p>
            <br />
            <p>
              From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
            </p>
            <br />
            <p>
              You may not create a link to this website from another website or document without prior written consent to us.
            </p>
            <br />
            <p>
              Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.
            </p>
            <br />
            <h3 className='text-xl font-semibold'>Accepting the terms:</h3>
            <br />
            <p>
              In order to use the services, you must first agree to the terms. You may not use the services if you do not accept the terms.
            </p>
            <br />
            <p>
              Clicking to accept or agree to the terms: Where this option is made available to you by igalaxy in the user interface for any service; or by actually using the services. In this case, you understand and agree that we will treat your use of the services as acceptance of the terms from that point onwards.
            </p>
            <br />
            <p>
              You may not use the services and may not accept the terms if you are not of legal age to form a binding contract with igalaxy, or you are a person barred from receiving the services under the laws of India.
            </p>
            <br />
            <h3 className='text-xl font-semibold'>Advertisements:</h3>
            <br />
            <p>
              Some of the services are supported by advertisements and sales promotions. These advertisements may be targeted to the content of information stored on the services, new launching, and queries made through the services or other information.
            </p>
            <br />
            <p>
              The manner, mode and extent of advertising by igalaxy on the services are subject to change without specific notice to you.
            </p>
            <br />
            <p>
              In consideration of Limton Private Limited granting you access to and use of the services, you agree that igalaxy may place such advertising on the services.
            </p>
            <br />
            <p>
              In case the phone of your choice is not in stock in the nearest store to your pin code, the delivery time might be increased but we will make sure the product reaches your doorstep at the earliest.
            </p>
            <br />
            <h3 className='text-xl font-semibold'>Submissions:</h3>
            <br />
            <p>
              If you submit, post or otherwise send us any information, content or materials including, without limitation, comments, reviews, data, text, messages, files, images, photographs, videos, audiovisual works, other persons’ names, likenesses, voices, usernames, profiles, actions, appearances, performances and/or other biographical information or material, and any other materials, as well as links to data, text, files, images, photographs, videos, audiovisual works, shall be entitled to unrestricted use of such submissions for any purpose at all, commercial or otherwise, without the requirement of any further permission from or payment to you or to any other person or entity, including without limitation for inclusion in any future gale publication or gale product, with no compensation to you.
            </p>
            <br />
            <p>
              No submission shall be subject to any obligation of confidentiality on our part and we shall not be liable for any use or disclosure of any submission. Without limiting the previous, you hereby grant us.
            </p>
            <br />
            <h3 className='text-xl font-semibold'>Payment and billing:</h3>
            <br />
            <p>
              You must select a payment method to pay us for any purchases you make from us. Every time you use the service, you reaffirm that we or our authorized billing agents are authorized to charge your designated payment method.
            </p>
            <br />
            <p>
              We may submit charges incurred under your account for payment, and you will be responsible for such charges, even if your membership is cancelled or terminated. You agree that we may charge your payment method for all amounts due to us without additional notice or consent unless required otherwise by law.
            </p>
            <br />
            <p>
              You are responsible for all charges incurred under your account made by you or anyone who uses your account (including your children, family or friends).
            </p>
            <br />
            <p>
              We may, in our discretion, post charges to your payment method individually or may aggregate your charges with other purchases you make on the site and apply those charges to your next billing cycle. All purchases for mobile, services, accessories, and all subscriptions are final and non-refundable.
            </p>
            <br />
            <p>
              You must notify us about any billing problems or discrepancies within 5 days after they first appear on the statement you receive from your bank or credit card company. If you do not bring such problems or discrepancies to our attention within 5 days, you agree that you waive the right to dispute such problems or discrepancies.
            </p>
            <br />
            <h3 className='text-xl font-semibold'>Proof of identification:</h3>
            <br />
            <p>
              Limton Private Limited reserves the right to ask for defined proof of identification at the time of delivery. Failure to provide the same to either the igalaxy or any of its associates can lead to cancellation of the order. In case of any gift items, the customer who has made the transaction online will have to provide his/her proof of identification.
            </p>
          </div>
        </section>
      </>
    );
  }
}

export default TermsConditions;
