import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AiFillThunderbolt } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";


const PreOwnedSmartphones = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const { type } = useParams();
  const scrollRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/api/product/type/Mobile`);
        setProducts(response.data.reverse());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleViewAll = () => {
    navigate('/Shop/Mobile');
  };

  const handleScroll = (direction) => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current;
      const scrollTo = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
      scrollRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });

      if (direction === 'left') {
        setShowRightArrow(true);
        if (scrollTo <= 0) {
          setShowLeftArrow(false);
        }
      } else {
        setShowLeftArrow(true);
        if (scrollTo + clientWidth >= scrollWidth) {
          setShowRightArrow(false);
        }
      }
    }
  };

  const SmartphoneCard = ({ product }) => {
    const { _id, product_type, product_name, Combination } = product;

    if (!Combination || !Combination['1comb']) {
      return <div className="border p-4 m-2 flex flex-col items-center bg-white shadow-lg rounded-lg">Invalid product data</div>;
    }

    const combinations = Combination['1comb'];
    const primaryCombination = combinations[0]; // Assuming you want to display the first combination
    const { attrs2comb, price, discounted_price } = primaryCombination;

    // Extract the first image from img_url within attrs2comb
    const firstImage = attrs2comb?.imgs?.[0]?.img_url?.[0] || '';

    const originalPrice = price;
    const discountedPrice = discounted_price;
    const discountPercentage = Math.round(((originalPrice - discountedPrice) / originalPrice) * 100);

    const handleCardClick = () => {
      navigate(`/product/${_id}`);
    };

    return (
      <div
        className="relative mt-5 p-2 md:p-4 flex items-center bg-white md:w-[360px] md:max-w-full rounded-lg cursor-pointer"
        onClick={handleCardClick}
      >
        <div className="">
          <img
            src={firstImage}
            alt={product_name}
            className="h-36 w-36 md:h-48 md:w-48 object-cover md:object-contain p-2 rounded-2xl"
          />
        </div>
        <div className="ml-4 flex flex-col justify-between h-full">
          <div>
            <h2 className="md:text-xl w-auto mb-2 line-clamp-2">{product_name}</h2>
            <p className="text-sm text-gray-500 mb-2">{product_type}</p>
            <div className="bg-slate-200 text-slate-500 mb-3 text-xs font-bold px-3 py-1 rounded-full flex w-2/3 items-center">
              <AiFillThunderbolt className='mr-1' /><p className='text-[10px]'>{discountPercentage}% OFF</p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-xl font-semibold text-black">₹{discountedPrice}</span>
            <span className="line-through text-md text-gray-500">₹{originalPrice}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="p-6 md:px-20 mt-5"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      
      <div className='flex justify-between'>
        <h2 className="text-xl sm:text-2xl font-medium mb-6">Smartphones You may like</h2>
        <div className='md:block sm:block'>
          <button onClick={handleViewAll} className="border border-slate-400 sm:py-2 sm:px-4 py-1 px-2 rounded-full">View All</button>
        </div>
      </div>

      <div className="relative">
      {isHovered && showLeftArrow && (
          <button
          className="absolute -ml-5 h-10 w-10 z-40 left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
          onClick={() => handleScroll('left')}
        >
          <IoIosArrowBack className='ml-1' />
        </button>
        )}
        <div
          className="flex overflow-x-auto gap-5 md:gap-7 no-scrollbar"
          ref={scrollRef}
          style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        >
          {products.map((product) => (
            <div key={product._id} className="min-w-[310px]">
              <SmartphoneCard product={product} />
            </div>
          ))}
        </div>
        {isHovered && showRightArrow && (
          <button
          className="absolute -mr-5 h-10 w-10 right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
          onClick={() => handleScroll('right')}
        >
          <IoIosArrowForward className='ml-1' />
        </button>
        )}
      </div>

      <style jsx>{`
        .no-scrollbar::-webkit-scrollbar {
          display: none; /* Hide scrollbar in webkit browsers */
        }
        .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }
      `}</style>
    </section>
  );
};

export default PreOwnedSmartphones;