import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/common/Navbar";
import Catcard from "./components/categories/Catcard";
import FlashSaleSection from "./components/categories/FlashSaleSection";
import NewReleases from "./components/categories/NewReleases";
import OffersDeals from "./components/categories/OffersDeals";
import Watchunder from "./components/categories/Watchunder";
import Tabletunder from "./components/categories/Tabletunder";
import Laptopunder from "./components/categories/Laptopunder";
import Footer from "./components/common/Footer";
import ProductDetail from "./components/categories/ProductDetail";
import Store from "./components/store/Store";
import Checkout from "./components/cart/Checkout";
import Slidingtext from "./components/categories/Slidingtext";
import Caraousel from "./components/categories/Caraousel";
import Brands from "./components/categories/Brands";
import Sell from "./pages/Sell";
import Repair from "./pages/Repair";
import Policy from "./components/common/Policy";
import Faq from "./components/common/Faq";
import Returns from "./components/common/Returns";
import Term from "./components/common/Term";
import Shipping from "./components/common/Shipping";
import Cancellation from "./components/common/Cancellation";
import Shop from "./components/store/StoredItem";
import Address from "./components/Profile/Address";
import Product from "./components/categories/ProductDetails";
import Cart from "./components/cart/Cart";
import Order from "./components/Profile/Orders";
import Check from "./components/categories/checkout2";
import Wishlist from "./components/cart/Wishlist";
import "@fontsource/urbanist";
import Search from "./components/categories/search";
import "@fontsource/urbanist/400-italic.css";
import { ToastContainer } from "react-toastify";
import TermsConditions from "./pages/TermsConditions";
import Callus from "../src/components/common/Callus";
import Invoice from "../src/components/common/invoice";
import Invoice1 from "../src/components/Invoice/Invoice1";
import Refurbished from "../src/components/categories/Refurbished";
import hotjar  from "@hotjar/browser";

const WHATSAPP_NUMBER = "9330096438";

function ScrollToTop() {
  const siteId = 5145031;
  const hotjarVersion = 6;

  hotjar.init(siteId, hotjarVersion);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function FloatingWhatsAppButton() {
  const handleClick = () => {
    window.open(`https://wa.me/${WHATSAPP_NUMBER}`, "_blank");
  };

  return (
    <button
      onClick={handleClick}
      className="fixed bottom-4 right-4 md:right-8 lg:right-8 rounded-full focus:outline-none"
      style={{ zIndex: 1000 }}
      aria-label="Chat with us on WhatsApp"
    >
      <img
        src="/assets/whatsapp.svg"
        alt="WhatsApp"
        className="h-12 w-12 md:h-16 md:w-16 lg:h-16 lg:w-16" // Adjust size for different screen sizes
      />
    </button>
  );
}

function AppContent() {
  const location = useLocation();
  const shouldRenderNavbar = !(
    location.pathname === "/" || location.pathname.startsWith("/product/")
  );

  return (
    <div
      className="font-urbanist"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#888 #f1f1f1",
      }}
    >
      <div className="sticky top-0 z-10">
        {<Navbar />} {/* Render Navbar except on "/" route */}
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Refurbished" element={<Refurbished />} />
        <Route path="/search" element={<Search />} />
        <Route path="/order/:orderid" element={<Invoice />} />
        <Route path="/Navbar" element={<Navbar />} />
        <Route path="/Caraousel" element={<Caraousel />} />
        <Route path="/Catcard" element={<Catcard />} />
        <Route path="/Slidingtext" element={<Slidingtext />} />
        <Route path="/FlashSaleSection" element={<FlashSaleSection />} />
        <Route path="/NewReleases" element={<NewReleases />} />
        <Route path="/Offersdeals" element={<OffersDeals />} />
        <Route path="/Watchunder" element={<Watchunder />} />
        <Route path="/Tabletunder" element={<Tabletunder />} />
        <Route path="/Laptopunder" element={<Laptopunder />} />
        <Route path="/Footer" element={<Footer />} />
        <Route path="/ProductDetail" element={<ProductDetail />} />
        <Route path="/Store" element={<Store />} />
        <Route path="/Checkout" element={<Checkout />} />
        <Route path="/Checkout2" element={<Check />} />
        <Route path="/Brands" element={<Brands />} />
        <Route path="/Sell" element={<Sell />} />
        <Route path="/Repair" element={<Repair />} />
        <Route path="/Policy" element={<Policy />} />
        <Route path="/Faq" element={<Faq />} />
        <Route path="/Address" element={<Address />} />
        <Route path="/returns" element={<Returns />} />
        <Route path="/term" element={<Term />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/Cancellation" element={<Cancellation />} />
        <Route path="/Shop/:type" element={<Shop />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/order" element={<Order />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/terms&conditions" element={<TermsConditions />} />
        <Route path="/callus" element={<Callus />} />
        <Route path="/Invoice" element={<Invoice1 />} />
      </Routes>
      <Footer />
      <FloatingWhatsAppButton />
    </div>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
      <style>
        {`
          /* For WebKit browsers */
          ::-webkit-scrollbar {
            width: 8px;
            height: 6px; /* Decrease the scrollbar height */
          }

          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          ::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 10px;
            border: 2px solid #f1f1f1;
          }

          ::-webkit-scrollbar-thumb:hover {
            background-color: #555;
          }

          /* For Firefox */
          * {
            scrollbar-width: thin;
            scrollbar-color: #888 #f1f1f1;
          }
        `}
      </style>
    </Router>
  );
}

export default App;
