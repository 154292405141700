import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

const CartButton = ({ handleGoShopping, handleGoToCart, total, saved, itemCount }) => {
  return (
    <div className="text-left mt-4 w-80 rounded-xl">
      <p className="text-[18px] font-normal text-black sm:text-[18px]">
        Your Cart ({itemCount})
      </p>
      <p className="text-sm mb-3 text-gray-500 ">
        Add your favorite items in your cart
      </p>
      <hr />
      <div className="mt-2 flex justify-between items-center">
        <p className="text-[18px] font-normal text-black sm:text-[16px]">
          TOTAL
        </p>
        <p className="text-[19px] font-normal text-black">
          ₹{total}
        </p>
      </div>
      <div className="flex justify-between items-center">
        <p className="text-sm mb-3 text-gray-500">
          No items yet
        </p>
        <p className="font-sm text-green-500">
          Saved ₹{saved}
        </p>
      </div>
      <hr />
      <div className="mt-3 flex justify-between">
        {/* <button
          type="button"
          className="inline-flex items-center bg-blue-700 rounded-full border border-blue px-6 py-2 text-[15px] font-normal text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
          onClick={handleGoShopping}
        >
          Go Shopping
        </button> */}
        <button
          type="button"
          className="inline-flex  items-center bg-blue-800 rounded-full border border-blue px-6 py-2 text-[15px] font-normal text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
          onClick={handleGoToCart}
        >
          Go to Cart
        </button>
      </div>
    </div>
  );
};

const Cartbtn = ({ onClose }) => {
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [saved, setSaved] = useState(0);
  const [itemCount, setItemCount] = useState(0);

  const handleGoShopping = () => {
    navigate('/'); // Assuming '/' is the route you want to navigate to
    toast.info("Redirecting to Shop");
    if (onClose) {
      onClose();
    }
  };

  const handleGoToCart = () => {
    navigate('/cart'); // Assuming '/cart' is the route for the cart page
    toast.info("Redirecting to Cart");
    if (onClose) {
      onClose();
    }
  };

  const fetchCartDetails = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || (!user.email && !user.contactNumber)) {
        console.log("User details incomplete");
        return;
      }
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${apiUrl}/api/users/carts`, {
        params: {
          email: user.email,
          contactNumber: user.contactNumber
        }
      });

      if (response.status === 200) {
        const { cartItems, totals } = response.data;
        setItemCount(cartItems.length);
        setTotal(totals.total);
        setSaved(totals.discount);
      } else {
        console.error('Failed to fetch cart details:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching cart details:', error.message);
    }
  };

  useEffect(() => {
    fetchCartDetails();
  }, []);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && onClose) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed top-14 md:right-1 z-50" ref={popupRef}>
      <div className="bg-white w-[390px] px-7 py-4 rounded-lg shadow-md">
        <CartButton handleGoShopping={handleGoShopping} handleGoToCart={handleGoToCart} total={total} saved={saved} itemCount={itemCount} />
        <ToastContainer />
      </div>
    </div>
  );
};

export default Cartbtn;
