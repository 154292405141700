import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

const FaqItem = ({ question, answer, isOpen, onClick }) => (
  <div className="cursor-pointer rounded-md transition-all duration-200">
    <button
      type="button"
      className="flex w-full items-center justify-between px-4 py-5 sm:p-6"
      onClick={onClick}
    >
      <span className="text-left text-lg font-medium text-black">{question}</span>
      {isOpen ? (
        <FaChevronUp className="h-5 w-5 text-gray-500" />
      ) : (
        <FaChevronDown className="h-5 w-5 text-gray-500" />
      )}
    </button>
    {isOpen && (
      <div className="px-4 pb-5 sm:px-6 sm:pb-6">
        <p className="text-left text-lg text-gray-600">{answer}</p>
      </div>
    )}
  </div>
);

export const FaqThree = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'How do I know my order is confirmed',
      answer:
        <p>
            <p>As soon you place an order.</p>
            <p>1. You will be redirected to the Order confirmation page on our website</p>
            <p>2. You will also recieve an order confirmation email on your registered email address and an orde confirmation SMS with the details of your order</p>
        </p>
    },
    {
      question: 'Can i order multiple units of single product?',
      answer:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat aliquam adipisci iusto aperiam? Sint asperiores sequi nobis inventore ratione deleniti?',
    },
    {
      question: 'When will my order be delivered?',
      answer:
        <p>
            <p>The time taken for delivery depends on the mode of delivery selected during checkout. the general timelines are as follows:</p>
            <p>1. Stsandard delivery - 7 working days from the order date</p>
            <p>Zip delivery - Orders till 4 pm get it same day and after 4pm customer would get the product next day before 1 pm</p>
        </p>,
    },
    {
        question: 'Can i change my delivery address after placing order?',
        answer:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat aliquam adipisci iusto aperiam? Sint asperiores sequi nobis inventore ratione deleniti?',
    },
    {
        question: 'What if the product is unsealed/damaged at the time of delivery?',
        answer:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat aliquam adipisci iusto aperiam? Sint asperiores sequi nobis inventore ratione deleniti?',
    },
    {
        question: 'I have not recieved my order, what should i do?',
        answer:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat aliquam adipisci iusto aperiam? Sint asperiores sequi nobis inventore ratione deleniti?',
    },
    {
        question: 'I have missed the delivery of my order what should i do?',
        answer:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat aliquam adipisci iusto aperiam? Sint asperiores sequi nobis inventore ratione deleniti?',
    },
    {
        question: 'Can i pay with my card on delivery?',
        answer:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat aliquam adipisci iusto aperiam? Sint asperiores sequi nobis inventore ratione deleniti?',
    },
    {
        question: 'When will i get GST benifit?',
        answer:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat aliquam adipisci iusto aperiam? Sint asperiores sequi nobis inventore ratione deleniti?',
    },
  ];

  return (
    <section className="mx-auto max-w-6xl px-5 py-10 md:px-0">
      <div>
        <div className="max-w-2xl">
          <h2 className="mx-5 text-xl mt-[40px] font-Medium leading-tight text-black sm:text-4xl lg:text-5xl">
            FAQs
          </h2>
        </div>
        <div className="mt-6 max-w-6xl space-y-6 md:mt-4 ">
          {faqs.map((faq, index) => (
            <FaqItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openIndex === index}
              onClick={() => toggleFaq(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FaqThree;
