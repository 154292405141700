import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';
import { MdDone } from 'react-icons/md';

const Filter = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    priceRange: [],
    newest: false,
    priceHighToLow: false,
    priceLowToHigh: false,
    // Other filters
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedFilters = { ...filters, [name]: checked };
    console.log("Checkbox change: ", name, checked, updatedFilters);
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const handlePriceRangeChange = (value) => {
    const updatedPriceRange = filters.priceRange.includes(value)
      ? filters.priceRange.filter((range) => range !== value)
      : [...filters.priceRange, value];

    const updatedFilters = { ...filters, priceRange: updatedPriceRange };
    console.log("Price range change: ", value, updatedFilters);
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const resetFilters = () => {
    const reset = {
      priceRange: [],
      newest: false,
      priceHighToLow: false,
      priceLowToHigh: false,
      // Other filters reset
    };
    console.log("Reset filters: ", reset);
    setFilters(reset);
    onFilterChange(reset);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <style jsx>{`
        .custom-checkbox {
          position: relative;
        }

        .custom-checkbox:checked::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 5px;
          height: 9px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        .sticky-filter {
          position: sticky;
          background-color: white;
          z-index: 5; /* Ensures the filter stays on top of other content */
          padding-top: 4px; /* Adjust if needed */
        }
      `}
      </style>
      <div className='p-4'>
        {/* Mobile filter button */}
        <button
          className="block sm:hidden bg-black text-white px-4 py-2 rounded-full mt-4"
          onClick={toggleModal}
        >
          Filters
        </button>

        {/* Filter content for larger screens */}
        <div className={`mt-12 top-36 hidden md:block md:w-60 lg:w-80 md:pl-12 pl-16 pr-8 filters sticky-filter`}>
          <h2 className="text-xl font-bold mb-4">Sort By</h2>
          <div className="mb-8">
            {/* <label className="block mb-2 text-black">
              <input
                type="checkbox"
                name="newest"
                className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                checked={filters.newest}
                onChange={handleCheckboxChange}
              /> Newest
            </label> */}
            <label className="block mb-2 text-gray-900">
              <input
                type="checkbox"
                name="priceHighToLow"
                className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                checked={filters.priceHighToLow}
                onChange={handleCheckboxChange}
              />
              Price (High to Low)
            </label>
            <label className="block mb-2 text-black">
              <input
                type="checkbox"
                name="priceLowToHigh"
                className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                checked={filters.priceLowToHigh}
                onChange={handleCheckboxChange}
              /> Price (Low to High)
            </label>
          </div>

          <h2 className="text-xl font-bold mb-4">Price Range</h2>
          <div className="mb-8">
            <label className="block mb-2 text-black">
              <input
                type="checkbox"
                value="0-10000"
                className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                checked={filters.priceRange.includes('0-10000')}
                onChange={() => handlePriceRangeChange('0-10000')}
              /> ₹0 - ₹10,000
            </label>
            <label className="block mb-2 text-black">
              <input
                type="checkbox"
                value="10001-25000"
                className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                checked={filters.priceRange.includes('10001-25000')}
                onChange={() => handlePriceRangeChange('10001-25000')}
              /> ₹10,001 - ₹25,000
            </label>
            <label className="block mb-2 text-black">
              <input
                type="checkbox"
                value="25001-50000"
                className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                checked={filters.priceRange.includes('25001-50000')}
                onChange={() => handlePriceRangeChange('25001-50000')}
              /> ₹25,001 - ₹50,000
            </label>
            <label className="block mb-2 text-black">
              <input
                type="checkbox"
                value="50001-100000"
                className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                checked={filters.priceRange.includes('50001-100000')}
                onChange={() => handlePriceRangeChange('50001-100000')}
              /> ₹50,001 - ₹100,000
            </label>
          </div>
          <button
            className="bg-black text-white px-4 py-2 rounded"
            onClick={resetFilters}
          >
            Clear All
          </button>
        </div>

        {/* Modal for mobile view */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-white z-50 p-4">
            <div className="max-w-sm mx-auto bg-gray-100 p-4 rounded-lg">
              <button
                className="bg-black text-white px-4 py-2 rounded mb-4"
                onClick={toggleModal}
              >
                X
              </button>

              <h2 className="text-xl font-bold mb-4">Sort By</h2>
              <div className="mb-8">
                {/* <label className="block mb-2 text-black">
                  <input
                    type="checkbox"
                    name="newest"
                    className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                    checked={filters.newest}
                    onChange={handleCheckboxChange}
                  /> Newest
                </label> */}
                <label className="block mb-2 text-gray-900">
                  <input
                    type="checkbox"
                    name="priceHighToLow"
                    className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                    checked={filters.priceHighToLow}
                    onChange={handleCheckboxChange}
                  />
                  Price (High to Low)
                </label>
                <label className="block mb-2 text-black">
                  <input
                    type="checkbox"
                    name="priceLowToHigh"
                    className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                    checked={filters.priceLowToHigh}
                    onChange={handleCheckboxChange}
                  /> Price (Low to High)
                </label>
              </div>

              <h2 className="text-xl font-bold mb-4">Price Range</h2>
              <div className="mb-8">
                <label className="block mb-2 text-black">
                  <input
                    type="checkbox"
                    value="0-10000"
                    className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                    checked={filters.priceRange.includes('0-10000')}
                    onChange={() => handlePriceRangeChange('0-10000')}
                  /> ₹0 - ₹10,000
                </label>
                <label className="block mb-2 text-black">
                  <input
                    type="checkbox"
                    value="10001-25000"
                    className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                    checked={filters.priceRange.includes('10001-25000')}
                    onChange={() => handlePriceRangeChange('10001-25000')}
                  /> ₹10,001 - ₹25,000
                </label>
                <label className="block mb-2 text-black">
                  <input
                    type="checkbox"
                    value="25001-50000"
                    className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                    checked={filters.priceRange.includes('25001-50000')}
                    onChange={() => handlePriceRangeChange('25001-50000')}
                  /> ₹25,001 - ₹50,000
                </label>
                <label className="block mb-2 text-black">
                  <input
                    type="checkbox"
                    value="50001-100000"
                    className="custom-checkbox mr-2 h-4 w-4 appearance-none border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none"
                    checked={filters.priceRange.includes('50001-100000')}
                    onChange={() => handlePriceRangeChange('50001-100000')}
                  /> ₹50,001 - ₹100,000
                </label>
              </div>
              <button
                className="bg-black text-white px-4 py-2 rounded"
                onClick={resetFilters}
              >
                Clear All
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Filter;
