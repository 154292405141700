import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { PiUserFocus } from "react-icons/pi";
import { BiMobileVibration } from "react-icons/bi";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { GoShieldCheck } from "react-icons/go";
import NewReleases from "./NewReleases";
import { Link } from "react-router-dom";

const images = [
  {
    original: "assets/laptop1un.png",
    thumbnail: "assets/laptop1un.png",
  },
  {
    original: "assets/mobile.jpg",
    thumbnail: "assets/mobile.jpg",
  },
  {
    original: "assets/mobile.jpg",
    thumbnail: "assets/mobile.jpg",
  },
  {
    original: "assets/mobile.jpg",
    thumbnail: "assets/mobile.jpg",
  },
];

const ProductDetail = () => {
  return (
    <div className="bg-white p-6 flex flex-col lg:flex-row">
      {/* Product image section */}
      <div className="lg:w-1/2 flex lg:sticky lg:top-0 lg:h-screen">
        <div className="flex lg:flex-col w-full">
          <div className="lg:w-6/6 w-full -mt-10 lg:order-2 order-1">
            <ImageGallery
              items={images}
              showThumbnails={true}
            />
          </div>
        </div>
      </div>

      {/* Product Detailed start */}
      <div className="lg:w-1/2 p-4">
        <h1 className="text-3xl mb-6">
          SAMSUNG Galaxy S24 Ultra 5G (12GB RAM, 1TB, Titanium Gray)
        </h1>
        <div className="text-2xl font-semibold mb-6">
          ₹1,59,999{" "}
          <span className="text-gray-500 text-lg line-through">₹1,64,999</span>
        </div>
        <div className="mb-4">
          <span className="text-lg font-semibold">Color:</span> Titanium Grey
          <div className="flex space-x-3 mt-1 mb-7">
            <div className="w-8 h-8 bg-gray-400 rounded-full border-2 border-gray-800"></div>
            <div className="w-8 h-8 bg-yellow-200 rounded-full border-2 border-gray-300"></div>
            <div className="w-8 h-8 bg-purple-400 rounded-full border-2 border-gray-300"></div>
            <div className="w-8 h-8 bg-gray-800 rounded-full border-2 border-gray-300"></div>
          </div>
        </div>
        <div className="mb-6 mt-4">
          <span className="text-lg mb-4 font-semibold">Storage:</span> 12+1 TB
          <div className="flex space-x-2 mt-4">
            <button className="px-4 py-2 border border-gray-300 rounded-full">
              12+256 GB
            </button>
            <button className="px-4 py-2 border border-gray-300 rounded-full">
              12+512 GB
            </button>
            <button className="px-4 py-2 border border-gray-900 rounded-full">
              12+1 TB
            </button>
          </div>
        </div>
        <div className="mb-4">
          <h2 className="font-semibold mb-2">Key Features:</h2>
          <ul className="list-disc list-inside">
            <span className="flex flex-row mt-2">
              <PiUserFocus className="text-xl mr-1 mt-1" /> 200 MP + 50 MP + 12
              MP + 10 MP Quad Rear & 12 MP Front Camera
            </span>
            <span className="flex flex-row mt-2">
              <BiMobileVibration className="text-xl mr-1 mt-1" /> 6.8 inches
              (17.27 cm), Dynamic AMOLED 2X, 120 Hz Refresh Rate
            </span>
            <span className="flex flex-row mt-2">
              <AiOutlineThunderbolt className="text-xl mr-1 mt-1" /> 5000 mAh
              with USB Type-C Charging
            </span>
            <span className="flex flex-row mt-2">
              <GoShieldCheck className="text-xl mr-1 mt-1" /> IP68 Water
              Resistant, Android 14 OS
            </span>
          </ul>
          <Link to="/" className="text-blue-500 mt-2 text-sm">
            View all features
          </Link>
        </div>
        <div className="flex space-x-4 mt-6">
          <button className="flex-1 bg-gray-200 text-gray-900 py-4 px-6 rounded-full">Add to Cart</button>
          <button className="flex-1 bg-blue-700 text-white border border-black rounded-full">Buy Now</button>
        </div>
      
      <div>
        <h2 className="text-lg font-semibold mb-2">Add Accessories:</h2>
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between items-center p-4 border border-gray-300 rounded bg-white">
            <div className="flex items-center space-x-4">
              <img src="assets/charger.jpg" alt="charger" className="w-16 h-16" />
              <div>
                <div>SAMSUNG 25W Type C Fast Charger (Adapter Only, Support PD 3.0 PPS, White)</div>
                <div className="text-gray-500 line-through">₹1,599.00</div>
              </div>
            </div>
            <div className="text-lg font-semibold">₹1,299.00</div>
          </div>
          <div className="flex justify-between items-center p-4 border border-gray-300 rounded bg-white">
            <div className="flex items-center space-x-4">
              <img src="assets/cable.jpg" alt="cable" className="w-16 h-16" />
              <div>
                <div>SAMSUNG Type A to Type C 5 Feet (1.5M) Cable (Multiple Device Compatibility, Black)</div>
                <div className="text-gray-500 line-through">₹599.00</div>
              </div>
            </div>
            <div className="text-lg font-semibold">₹499.00</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ProductDetail;
