import React, { Component } from "react";
import Catcard from "../components/categories/Catcard";
import FlashSaleSection from "../components/categories/FlashSaleSection";
import NewReleases from "../components/categories/NewReleases";
import Watchunder from "../components/categories/Watchunder";
import Tabletunder from "../components/categories/Tabletunder";
import Laptopunder from "../components/categories/Laptopunder";
import Slidingtext from "../components/categories/Slidingtext";
import Caraousel from "../components/categories/Caraousel";
import { ToastContainer } from "react-toastify";
import Navbar from "../components/common/Navbar";
import Refurbished from '../components/categories/Refurbished';

export class Home extends Component {
  render() {
    return (
      <div className="font-urbanist bg-gray-50">
        <div className="relative z-0">
          <Caraousel />
        </div>
        <div>
          <Catcard />
        </div>
        <div>
          <div className="bg-gradient-to-r from-[#0049BA] via-[#0064FF] to-[#0049BA] h-12 mt-5">
            <Slidingtext />
          </div>
        </div>
        <div>
          <FlashSaleSection />
        </div>
        <div>
          <NewReleases />
        </div>
        {/* <div className='visible md:block lg:hidden'>
          <OffersDeals />
        </div> */}
        <div>
          <Refurbished />
        </div>
        <div>
          <Watchunder />
        </div>
        <div>
          <Tabletunder />
        </div>
        <div>
          <Laptopunder />
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default Home;
